import { configureStore } from '@reduxjs/toolkit';
import credentials from './modules/credentials';
import items from './modules/items';
import locations from './modules/locations';
import stores from './modules/stores';
import user from './modules/user';
import stocks from './modules/stocks';
import brands from './modules/brands';

const store = configureStore({
  reducer: {
    credentials: credentials,
    items: items,
    brands: brands,
    locations: locations,
    stores: stores,
    stocks: stocks,
    user: user,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

export type AppDispatch = typeof store.dispatch;
export default store;