import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Autocomplete, Box, Button, Checkbox, CircularProgress, Divider, FormControlLabel, FormGroup, Grid, MenuItem, Paper, Select, SelectChangeEvent, TextField } from '@mui/material';

import { Brand, StockCategory, StockComponent, StockIngredient, StockItem, StockPreparation } from '../../model/model';
import { AppDispatch } from '../../store/store';
import { selectLocation } from '../../store/modules/locations';
import { DisplayNumber, PriceFormat } from '../../pkg/pkg';
import { postStock, selectAllItems, selectCategories, selectIngredients, selectPreparations, selectStockLoadings } from '../../store/modules/stocks';
import { selectOrganization } from '../../store/modules/user';
import { selectAllBrands } from '../../store/modules/brands';


export interface RefProps {
  children: Props;
  forwardRef?: React.Ref<HTMLDivElement>;
}

export interface Props {
  path: string;
  item: StockItem;
  option: StockItem;
  brandUuid: string | null;
  preparation: StockPreparation;
  ingredient: StockIngredient;
  category: StockCategory | null;
}

const StockView = ({ children, forwardRef }: RefProps) => {
  const { path, item, option, brandUuid, preparation, ingredient, category } = children;
  const organization = useSelector(selectOrganization);
  const items: StockItem[] = useSelector(selectAllItems);
  const brands: Brand[] = useSelector(selectAllBrands);
  const preparations: StockPreparation[] = useSelector(selectPreparations);
  const ingredients: StockIngredient[] = useSelector(selectIngredients);
  const categories: StockCategory[] = useSelector(selectCategories);
  const loadings = useSelector(selectStockLoadings);
  const [value, setValue] = useState('');
  const [error, setError] = useState('');

  // init form
  const [values, setValues] = React.useState({
    name: path == 'item' ? item.name : path == 'option' ? option.name : path == 'preparation' ? preparation.name : path == 'ingredient' ? ingredient.name : null,
    price: path == 'item' ? item.takeout_price : path == 'option' ? option.takeout_price : path == 'ingredient' ? ingredient.price : null,
    deliveryPrice: path == 'item' ? item.delivery_price : path == 'option' ? option.delivery_price : null,
    totalCost: path == 'item' ? item.total_cost : path == 'option' ? option.total_cost : path == 'preparation' ? preparation.total_cost : null,
    takeoutCostRate: path == 'item' ? item.takeout_cost_rate : path == 'option' ? option.takeout_cost_rate : null,
    deliveryCostRate: path == 'item' ? item.delivery_cost_rate : path == 'option' ? option.delivery_cost_rate : null,
    stockQuantity: path == 'ingredient' ? ingredient.stock_quantity : path == 'preparation' ? preparation.stock_quantity : null,
    stockUnit: path == 'ingredient' ? ingredient.stock_unit : path == 'preparation' ? preparation.stock_unit : null,
    stockPrice: path == 'ingredient' ? ingredient.stock_price : path == 'preparation' ? preparation.stock_cost : null,
    unitQuantity: path == 'ingredient' ? ingredient.unit_quantity : path == 'preparation' ? preparation.volume : null,
    unit: path == 'ingredient' ? ingredient.unit : path == 'preparation' ? preparation.unit : null,
    unitPrice: path == 'ingredient' ? ingredient.unit_price : path == 'preparation' ? preparation.unit_cost : null,
    taxRate: path == 'item' ? item.tax_rate : path == 'option' ? option.tax_rate : path == 'ingredient' ? ingredient.tax_rate : null,
    components: path == 'item' ? item.components : path == 'option' ? option.components : path == 'preparation' ? preparation.components : null,
    brandUuid: path == 'item' ? item.brand_uuid : undefined,
    categoryUuid: path == 'ingredient' ? ingredient.category_uuid : null,
    addComponents: [] as string[],
    removeComponents: [] as string[],
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name == 'name') {
      if (path == 'ingredient') {
        if (ingredients.filter((ing: StockIngredient) => ing.name == event.target.value).length > 0) {
          setError('同名の材料があります');
        } else {
          setError('');
        }
      } else if (path == 'preparation') {
        if (preparations.filter((prep: StockPreparation) => prep.name == event.target.value).length > 0) {
          setError('同名の仕込みがあります');
        } else {
          setError('');
        }
      }
    }
    
    if (event.target.name == 'ingQuantity') {
      var newComponents = values.components!.reduce((resultArray: StockComponent[], component, id) => {
        if (id == Number(event.target.id)) {
          resultArray[id] = new StockComponent({
            type: component.type,
            uuid: component.uuid,
            name: component.name,
            unit: component.unit,
            unit_cost: component.unit_cost,
            quantity: Number(event.target.value),
            total_cost: Number(event.target.value) * component.unit_cost
          });
        } else {
          resultArray[id] = component;
        }
        return resultArray;
      }, []);
      var totalCost = newComponents.reduce((sum, component) => sum + component.total_cost, 0);
      setValues({
        ...values,
        components: newComponents,
        totalCost: totalCost,
        unitPrice: Math.round(totalCost * 1000 / values.unitQuantity!) / 1000,
        stockPrice: Math.round(totalCost * 1000 / values.stockQuantity!) / 1000,
      });
      return;
    }
    
    var newValues = {
      ...values,
      [event.target.name]: event.target.value,
    }  
    if (event.target.name == 'stockQuantity' || event.target.name == 'price') {
      newValues.stockPrice = Math.round(Number(path == 'ingredient' ? newValues.price : newValues.totalCost!) * 1000 / Number(newValues.stockQuantity!)) / 1000;
    }
    if (event.target.name == 'unitQuantity' || event.target.name == 'price') {
      newValues.unitPrice = Math.round(Number(path == 'ingredient' ? newValues.price : newValues.totalCost!) * 1000 / Number(newValues.unitQuantity!)) / 1000;
    }
    setValues(newValues);
  };

  const handleSelectChange = (event: SelectChangeEvent) => {
    if (event.target.name == 'copyComponent') {
      var item = items.filter((item: StockItem) => item.uuid == event.target.value)[0];
      values.components = item.components;
      var totalCost = item.components.reduce((sum, component) => sum + component.total_cost, 0);
      setValues({
        ...values,
        price: values.price,
        totalCost: totalCost,
        unitPrice: Math.round(totalCost * 1000 / values.unitQuantity!) / 1000,
        stockPrice: Math.round(totalCost * 1000 / values.stockQuantity!) / 1000,
      });
      return;
    }
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  }

  const handleDelete = (index: number) => {
    var components = [...values.components!];
    components.splice(index,1);
    setValues({
      ...values,
      components: components,
      price: values.price,
    });
  }

  const [selectionValue, setSelectionValue] = useState(null as string | null);
  const selections = () => {
    var options = [];
    var params:any = {};
    if (path == 'item' || path == 'option') {
      for (var i in preparations) {
        var title = '[仕込み]' + preparations[i].name;
        options.push(title);
        params[title] = new StockComponent({
          type: 'preparation',
          uuid: preparations[i].uuid!,
          name: preparations[i].name,
          unit: preparations[i].unit,
          unit_cost: preparations[i].unit_cost,
          quantity: 0,
          total_cost: 0,
        });
      }
    }
    for (var i in ingredients) {
      var title = '[材料]' + ingredients[i].name;
      options.push(title);
      params[title] = new StockComponent({
        type: 'ingredient',
        uuid: ingredients[i].uuid!,
        name: ingredients[i].name,
        unit: ingredients[i].unit,
        unit_cost: ingredients[i].unit_price,
        quantity: 0,
        total_cost: 0,
      });
    }

    return {
      options: options,
      params: params,
    }
  }
  // handle submit
  const dispatch = useDispatch<AppDispatch>();
  const handleButton = () => {
    if (path == 'item') {
      dispatch(postStock({path: path, ItemProps: {
        uuid: item.uuid,
        name: values.name!,
        organization_uuid: organization.uuid,
        brand_uuid: values.brandUuid,
        takeout_price: Number(values.price),
        delivery_price: Number(values.deliveryPrice),
        tax_rate: Number(values.taxRate),
        row: item.row,
        components: values.components!,
      }}));
    } else if (path == 'option') {
      dispatch(postStock({path: path, ItemProps: {
        uuid: option.uuid,
        name: values.name!,
        organization_uuid: organization.uuid,
        takeout_price: Number(values.price),
        delivery_price: Number(values.deliveryPrice),
        tax_rate: Number(values.taxRate),
        row: option.row,
        components: values.components!,
      }}));
    } else if (path == 'preparation') {
      dispatch(postStock({path: path, PreparationProps: {
        uuid: preparation.uuid,
        name: values.name!,
        organization_uuid: organization.uuid,
        stock_quantity: Number(values.stockQuantity),
        stock_unit: values.stockUnit!,
        volume: Number(values.unitQuantity),
        unit: values.unit!,
        row: preparation.row ? preparation.row : preparations.length + 1,
        components: values.components!,
      }}));
    } else if (path == 'ingredient') {
      dispatch(postStock({path: path, IngredientProps: {
        uuid: ingredient.uuid,
        name: values.name!,
        category_uuid: values.categoryUuid,
        organization_uuid: organization.uuid,
        price: Number(values.price),
        stock_quantity: Number(values.stockQuantity),
        stock_unit: values.stockUnit!,
        stock_price: Number(values.stockPrice),
        unit_quantity: Number(values.unitQuantity),
        unit: values.unit!,
        unit_price: Number(values.unitPrice),
        tax_rate: Number(values.taxRate),
        row: ingredient.row ? ingredient.row : ingredients.length + 1,
      }}));
    } else if (path == 'category') {
      dispatch(postStock({path: path, CategoryProps: {
        name: values.name!,
        organization_uuid: organization.uuid,
      }}));
    }
  }

  return (
    <Paper className='FontM' sx={{
      display: 'flex',
      flexFlow: 'column',
      width: 'calc(100% - 140px)',
      maxWidth: '1000px',
      maxHeight: '80vh',
      background: 'white',
      overflowY: 'scroll',
      p: '10px',
    }}>
      {loadings.post ? <CircularProgress sx={{m:'auto'}} /> :
        <Grid container mb='10px'>
          <Grid item xs={12} sx={{m: '10px'}}>
            <TextField sx={{width: '100%'}} id="name" name="name" label="Name" variant="filled"
              defaultValue={values.name}
              onChange={handleChange}/>
          </Grid>
          { (path == 'item'|| path == 'option') ?
            <Grid item xs={5} sx={{p: '10px'}}>
              <TextField sx={{width: '100%'}} id="price" name="deliveryPrice" label="デリバリー価格（税込）" variant="filled" type="number"
                defaultValue={values.deliveryPrice}
                onChange={handleChange}/>
            </Grid> : null
          }
          { (path == 'item' || path == 'option' || path == 'ingredient') ?
            <Grid item xs={5} sx={{p: '10px'}}>
              <TextField sx={{width: '100%'}} id="price" name="price" label={path == 'ingredient' ? "税抜価格" : "テイクアウト価格（税込）"} variant="filled" type="number"
                defaultValue={values.price}
                onChange={handleChange}/>
            </Grid> : null
          }
          { (path == 'item' || path == 'option' || path == 'ingredient') ? <>
              <Grid item xs={2} sx={{p: '10px'}}>
                <Select
                  sx={{width: '100%', maxWidth: '300px'}}
                  id="tax_rate"
                  name="taxRate"
                  label="消費税率"
                  value={String(values.taxRate)}
                  onChange={handleSelectChange}
                >
                  <MenuItem value={0.08} key='0.08'>8%</MenuItem>
                  <MenuItem value={0.1} key='0.1'>10%</MenuItem>
                </Select>
              </Grid>
            </> : null
          }
          { (path == 'item' || path == 'option' || path == 'preparation') ?
            <Grid item xs={12} sx={{m: '10px'}}>
              {'総原価: ' + values.totalCost!.toLocaleString()
                + (path == 'item' ? 
                  ' / テイクアウト原価率: ' + (Math.round(values.totalCost! * 1000 / (values.price! / (1 + values.taxRate!))) / 10).toLocaleString() + '%'
                + ' / デリバリー原価率: ' + (Math.round(values.totalCost! * 1000 / (values.deliveryPrice! / (1 + values.taxRate!))) / 10).toLocaleString() + '%'
                : '')}
            </Grid> : null
          }
          { (path == 'ingredient' || path == 'preparation') ? <>
              <Grid item xs={6} sx={{p: '10px'}}>
                <TextField sx={{width: '100%'}} id="unit_quantity" name="unitQuantity" label="出来高" variant="filled" type="number"
                  defaultValue={values.unitQuantity}
                  onChange={handleChange}/>
              </Grid>
              <Grid item xs={2} sx={{p: '10px'}}>
                <TextField sx={{width: '100%'}} id="unit" name="unit" label="単位" variant="filled"
                  defaultValue={values.unit}
                  onChange={handleChange}/>
              </Grid>
              <Grid item xs={4} sx={{p: '10px'}}>
                {'¥ ' + PriceFormat(values.unitPrice!, 0) + ' / ' + values.unit}
              </Grid>
            </> : null
          }
          { (path == 'ingredient' || path == 'preparation') ? <>
              <Grid item xs={6} sx={{p: '10px'}}>
                <TextField sx={{width: '100%'}} id="stock_quantity" name="stockQuantity" label="棚卸数量" variant="filled" type="number"
                  defaultValue={values.stockQuantity}
                  onChange={handleChange}/>
              </Grid>
              <Grid item xs={2} sx={{p: '10px'}}>
                <TextField sx={{width: '100%'}} id="stock_unit" name="stockUnit" label="単位" variant="filled"
                  defaultValue={values.stockUnit}
                  onChange={handleChange}/>
              </Grid>
              <Grid item xs={4} sx={{p: '10px'}}>
                {'¥ ' + PriceFormat(values.stockPrice!, 0) + ' / ' + values.stockUnit}
              </Grid>
            </> : null
          }
          { path == 'item' ? 
            <Grid container sx={{
              p: '10px 20px',
            }}>
              <Grid item xs={12}>
                <Select
                  sx={{width: '100%', mt: '10px', maxWidth: '600px'}}
                  id="brandUuid"
                  name="brandUuid"
                  label="ブランド"
                  value={values.brandUuid}
                  onChange={handleSelectChange}
                >
                  { brands.map((brand: Brand) => (
                    <MenuItem value={brand.uuid!} key={brand.uuid!}>{brand.name}</MenuItem>
                  )) }
                </Select>
              </Grid>
            </Grid>: null
          }
          { path == 'ingredient' ? 
            <Grid container sx={{
              p: '10px 20px',
            }}>
              <Grid item xs={12}>
                <Select
                  sx={{width: '100%', mt: '10px', maxWidth: '600px'}}
                  id="categoryUuid"
                  name="categoryUuid"
                  label="カテゴリー"
                  value={values.categoryUuid ? values.categoryUuid : undefined}
                  onChange={handleSelectChange}
                >
                  { categories.map((category: StockCategory) => (
                    <MenuItem value={category.uuid} key={category.uuid}>{category.name}</MenuItem>
                  )) }
                </Select>
              </Grid>
            </Grid>: null
          }
          { (path == 'item' || path == 'option' || path == 'preparation') ? <>
                <Grid container sx={{
                  p: '10px 20px',
                }}>
                { values.components!.map((component:StockComponent, index: number) => (
                  <Grid container key={String(index)} className='FontS'>
                    <Grid item xs={6}>{component.name}</Grid>
                    <Grid item xs={2} textAlign='right'>
                      <TextField sx={{width: '100%'}} id={String(index)} name="ingQuantity" size="small" label="数量" variant="filled" type="number"
                        defaultValue={component.quantity}
                        onChange={handleChange}/>
                    </Grid>
                    <Grid item xs={0.5} textAlign='left'>
                      {component.unit}
                    </Grid>
                    <Grid item xs={1.5} textAlign='right' sx={{mr: '10px'}}>
                      {DisplayNumber(component.total_cost, '')}
                    </Grid>
                    <Grid item xs={1} textAlign='center'>
                      <Button sx={{m: '10px'}} color="error" variant="contained" onClick={() => handleDelete(index)}>×</Button>
                    </Grid>
                  </Grid>
                ))}
                <Autocomplete
                  sx={{width: '100%', mt: '10px', maxWidth: '600px'}}
                  id="newComponent"
                  value={selectionValue}
                  options={selections().options}
                  renderInput={(params) => <TextField {...params} label="新しい材料" />}
                  onChange={(event: any, newValue: string | null) => {
                    if(newValue) {
                      values.components = [...values.components!, selections().params[newValue]]
                      setValues({
                        ...values,
                        price: values.price,
                      });
                      setSelectionValue(null);
                    }
                  }}
                />
                { path == 'item' ? 
                  <Grid item xs={12}>
                  <p>↓他のメニューから材料をコピーする</p>
                  <Select
                    sx={{width: '100%', mt: '10px', maxWidth: '600px'}}
                    id="copyComponent"
                    name="copyComponent"
                    label="コピー"
                    value={value}
                    onChange={handleSelectChange}
                  >
                    { items.map((item: StockItem) => (
                      <MenuItem value={item.uuid!} key={item.uuid!}>{item.name}</MenuItem>
                    )) }
                  </Select></Grid> : null
                }
              </Grid>
            </> : null
          }
          <Grid item xs={12} sx={{textAlign: 'center', mt: '10px'}}>
            <Button sx={{m: '10px'}} disabled={error != ''} variant="contained" onClick={handleButton}>Apply</Button>
          </Grid>
          {error != '' ?
            <Grid item xs={12} sx={{textAlign: 'center', color: 'red'}}>
              {error}
            </Grid>
            : null
            }
        </Grid>
      }
    </Paper>
  );
};

export default StockView;