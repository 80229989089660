import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios';
import { BrandItem, StockCategory, StockComponent, StockIngredient, StockItem, StockPreparation } from '../../model/model';
import dayjs, { Dayjs } from 'dayjs';
import { DateRange } from '@mui/x-date-pickers-pro';

const initialState = {
  itemSummary: {
    data: [],
    total: [],
  },
  prepSummary: {
    data: [],
    total: [],
  },
  ingSummary: {
    data: [],
    total: [],
  },
  dateRange: [dayjs().add(-7, 'd'), dayjs().add(-1, 'd')] as DateRange<Dayjs>,
  brandUuid: '',
  unlinkItems: [],
  brandItems: [] as BrandItem[],
  options: [] as StockItem[],
  preparations: [] as StockPreparation[],
  ingredients: [] as StockIngredient[],
  categories: [] as StockCategory[],
  loadings: {
    itemSummary: false,
    compSummary: false,
    unlinkItems: false,
    post: false,
    items: false,
    options: false,
    preparations: false,
    ingredients: false
  },
  error: false,
}

type PostProps = {
  path: string,
  ItemProps?: ItemProps | null,
  PreparationProps?: PreparationProps | null,
  IngredientProps?: IngredientProps | null,
  CategoryProps?: CategoryProps | null,
}

type ItemProps = {
  uuid: string | null;
  organization_uuid: string,
  brand_uuid?: string,
  name: string;
  takeout_price: number;
  delivery_price: number;
  tax_rate: number;
  row: number;
  components: StockComponent[];
}

type IngredientProps = {
  uuid: string | null,
  organization_uuid: string,
  category_uuid: string | null,
  name: string,
  price: number,
  stock_quantity: number,
  stock_unit: string,
  stock_price: number,
  unit_quantity: number,
  unit: string,
  unit_price: number,
  tax_rate: number,
  row: number,
}

type PreparationProps = {
  uuid: string | null,
  organization_uuid: string,
  name: string,
  volume: number,
  unit: string,
  stock_quantity: number,
  stock_unit: string,
  components: StockComponent[],
  row: number,
}

type CategoryProps = {
  name: string,
  organization_uuid: string,
}

export const postStock = createAsyncThunk('stocks/postStock', async (prop:PostProps) => {
  const body =
    prop.path == 'item' ? prop.ItemProps :
    prop.path == 'option' ? prop.ItemProps :
    prop.path == 'preparation' ? prop.PreparationProps :
    prop.path == 'ingredient' ? prop.IngredientProps :
    prop.path == 'category' ? prop.CategoryProps : null;
  
  const res = await axios.post('/stocks/'+prop.path, body, );
  return res.data;
});

export const postStockRow = createAsyncThunk('stocks/postStockRow', async (param:{type: string, uuid:string; row: number}[]) => {
  const res = await axios.post('/stocks/row', {data: param});
  return res.data;
});


export const getItems = createAsyncThunk('stocks/getItems', async (organizationUuid:string) => {
  const res = await axios.get('/stocks/items?organization_uuid='+organizationUuid);
  return res.data;
});

export const getOptions = createAsyncThunk('stocks/getOptions', async (organizationUuid:string) => {
  const res = await axios.get('/stocks/options?organization_uuid='+organizationUuid);
  return res.data;
});

export const getPreparations = createAsyncThunk('stocks/getPreparations', async (organizationUuid:string) => {
  const res = await axios.get('/stocks/preparations?organization_uuid='+organizationUuid);
  return res.data;
});

export const getIngredients = createAsyncThunk('stocks/getIngredients', async (organizationUuid:string) => {
  const res = await axios.get('/stocks/ingredients?organization_uuid='+organizationUuid);
  return res.data;
});

type DateProps = {
  fromDate: string,
  toDate: string,
  locationUuid:string
  brandUuid?:string
  ofoSlug?:string
}

export const getItemSummary = createAsyncThunk('stocks/getItemSummary', async (props:DateProps) => {
  const res = await axios.get(`/stocks/summary/items?from_date=${props.fromDate}&to_date=${props.toDate}&location_uuid=${props.locationUuid}${props.brandUuid ? '&brand_uuid='+props.brandUuid : ''}${props.ofoSlug ? '&ofo_slug='+props.ofoSlug : ''}`);
  return res.data;
});

export const getUnlinkItems = createAsyncThunk('stocks/getUnlinkItems', async (props:DateProps) => {
  const res = await axios.get(`/stocks/unlink?from_date=${props.fromDate}&to_date=${props.toDate}&location_uuid=${props.locationUuid}${props.brandUuid ? '&brand_uuid='+props.brandUuid : ''}`);
  return res.data;
});

export const getCompSummary = createAsyncThunk('stocks/getCompSummary', async (props:DateProps) => {
  const res = await axios.get(`/stocks/summary/components?from_date=${props.fromDate}&to_date=${props.toDate}&location_uuid=${props.locationUuid}${props.brandUuid ? '&brand_uuid='+props.brandUuid : ''}${props.ofoSlug ? '&ofo_slug='+props.ofoSlug : ''}`);
  return res.data;
});

type LinkProps = {
  order_item_id: string,
  menu_item_uuid: string,
  location_uuid?: string,
}

export const postLinkItems = createAsyncThunk('stocks/postLinkItems', async (prop:LinkProps) => {
  const res = await axios.post('/stocks/link', prop);
  return res.data;
});

const stockSlice = createSlice({
    name: 'stocks',
    initialState,
    reducers: {
      resetStockItems: (state) => {
        state.brandItems = [];
        state.options = [];
        state.preparations = [];
        state.ingredients = [];
      },
      setDateRange: (state, action: PayloadAction<DateRange<Dayjs>>) => {
        state.dateRange = action.payload;
      },
      setBrandUuid: (state, action: PayloadAction<string>) => {
        state.brandUuid = action.payload;
      },
      setNewObject: (state, action: PayloadAction<{
        items?: StockItem[];
        options?: StockItem[];
        preparations?: StockPreparation[];
        ingredients?: StockIngredient[];
      }>) => {
        const {items, options, preparations, ingredients} = action.payload;
        if (items) {
          state.brandItems.filter((b:BrandItem) => b.brand_uuid == state.brandUuid)[0].items = items;
        } else if (options) {
          state.options = options;
        } else if (preparations) {
          state.preparations = preparations;
        } else if (ingredients) {
          state.ingredients = ingredients;
        }
      },
    },
    extraReducers: (builder) => {
      builder.addCase(getItems.pending, (state, action) => {
        state.loadings.items = true;
      });
      builder.addCase(getItems.fulfilled, (state, action) => {
        state.loadings.items = false;
        state.brandItems = action.payload.data;
      });
      builder.addCase(getItems.rejected, (state, action) => {
        state.loadings.items = false;
        state.error = true;
      });
      builder.addCase(getOptions.pending, (state, action) => {
        state.loadings.options = true;
      });
      builder.addCase(getOptions.fulfilled, (state, action) => {
        state.loadings.options = false;
        state.options = action.payload.data;
      });
      builder.addCase(getOptions.rejected, (state, action) => {
        state.loadings.options = false;
        state.error = true;
      });
      builder.addCase(getPreparations.pending, (state, action) => {
        state.loadings.preparations = true;
      });
      builder.addCase(getPreparations.fulfilled, (state, action) => {
        state.loadings.preparations = false;
        state.preparations = action.payload.data;
      });
      builder.addCase(getPreparations.rejected, (state, action) => {
        state.loadings.preparations = false;
        state.error = true;
      });
      builder.addCase(getIngredients.pending, (state, action) => {
        state.loadings.ingredients = true;
      });
      builder.addCase(getIngredients.fulfilled, (state, action) => {
        state.loadings.ingredients = false;
        state.ingredients = action.payload.data.ingredients;
        state.categories = action.payload.data.categories;
      });
      builder.addCase(getIngredients.rejected, (state, action) => {
        state.loadings.ingredients = false;
        state.error = true;
      });
      builder.addCase(postStock.pending, (state, action) => {
        state.loadings.post = true;
      });
      builder.addCase(postStock.fulfilled, (state, action) => {
        state.loadings.post = false;
      });
      builder.addCase(postStock.rejected, (state, action) => {
        state.loadings.post = false;
        state.error = true;
      });
      builder.addCase(getItemSummary.pending, (state, action) => {
        state.loadings.itemSummary = true;
      });
      builder.addCase(getItemSummary.fulfilled, (state, action) => {
        state.itemSummary = action.payload.data;
        state.loadings.itemSummary = false;
      });
      builder.addCase(getItemSummary.rejected, (state, action) => {
        state.loadings.itemSummary = false;
        state.error = true;
      });
      builder.addCase(getCompSummary.pending, (state, action) => {
        state.loadings.compSummary = true;
      });
      builder.addCase(getCompSummary.fulfilled, (state, action) => {
        state.prepSummary = action.payload.data.preparations;
        state.ingSummary = action.payload.data.ingredients;
        state.loadings.compSummary = false;
      });
      builder.addCase(getCompSummary.rejected, (state, action) => {
        state.loadings.compSummary = false;
        state.error = true;
      });
      builder.addCase(getUnlinkItems.pending, (state, action) => {
        state.loadings.unlinkItems = true;
      });
      builder.addCase(getUnlinkItems.fulfilled, (state, action) => {
        state.loadings.unlinkItems = false;
        state.unlinkItems = action.payload.data;
      });
      builder.addCase(getUnlinkItems.rejected, (state, action) => {
        state.loadings.unlinkItems = false;
      });
      builder.addCase(postLinkItems.fulfilled, (state, action) => {
        state.unlinkItems = state.unlinkItems.filter((i:any) => i.item_id != action.payload.data.order_item_id);
      });
    },
})

export const { resetStockItems, setDateRange, setBrandUuid, setNewObject } = stockSlice.actions

export const selectDateRange = (state: any) => {return state.stocks.dateRange};
export const selectItemSummary = (state: any) => {return state.stocks.itemSummary};
export const selectPrepSummary = (state: any) => {return state.stocks.prepSummary};
export const selectIngSummary = (state: any) => {return state.stocks.ingSummary};
export const selectBrandItems = (state: any) => {return state.stocks.brandItems};
export const selectOptions = (state: any) => {return state.stocks.options};
export const selectAllItems = (state: any) => { 
  if (!state.stocks.brandUuid) {
    return [];
  }
  return state.stocks.brandItems.filter((b: BrandItem) => b.brand_uuid == state.stocks.brandUuid)[0].items;
};
export const selectBrandItemOpens = (state: any) => {return state.stocks.brandItemOpens};
export const selectStockLoadings = (state: any) => {return state.stocks.loadings};
export const selectPreparations = (state: any) => {return state.stocks.preparations};
export const selectCategories = (state: any) => {return state.stocks.categories};
export const selectIngredients = (state: any) => {return state.stocks.ingredients};
export const selectUnlinkItems = (state: any) => {return state.stocks.unlinkItems};
export const selectBrandUuid = (state: any) => {return state.stocks.brandUuid};

export default stockSlice.reducer