import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';

const initialState = {
  brandList: [],
  brands: [],
  loading: false,
  error: false,
}

export const getBrandList = createAsyncThunk('brands/getBrandList', async () => {
  const res = await axios.get('/brands/list');
  return res.data;
});

export const getAllBrands = createAsyncThunk('brands/getAllBrands', async (uuid: string) => {
  const res = await axios.get('/brands/all?organization_uuid='+uuid);
  return res.data;
});

type AddProps = {
  brand_uuid: string,
  location_uuid: string,
}

export const addBrand = createAsyncThunk('brands/addBrand', async (props: AddProps) => {
  const res = await axios.post('/brands/add', props);
  return res.data;
});

type FreeeProps = {
  brand_uuid: string,
  item_id: string,
}

export const connectBrandFreee = createAsyncThunk('brands/connectFreee',async (prop:FreeeProps) => {
  const res = await axios.post('/brands/freee', prop);
  return res.data;
});

const brandSlice = createSlice({
    name: 'brands',
    initialState,
    reducers: {
      resetBrandList: (state) => {
        state.brandList = [];
      },
    },
    extraReducers: (builder) => {
      builder.addCase(getBrandList.pending, (state, action) => {
        state.loading = true;
      });
      builder.addCase(getBrandList.fulfilled, (state, action) => {
        state.loading = false;
        state.brandList = action.payload.data;
      });
      builder.addCase(getBrandList.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      });
      builder.addCase(getAllBrands.pending, (state, action) => {
        state.loading = true;
      });
      builder.addCase(getAllBrands.fulfilled, (state, action) => {
        state.loading = false;
        state.brands = action.payload.data;
      });
      builder.addCase(getAllBrands.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      });
      builder.addCase(addBrand.pending, (state, action) => {
        state.loading = true;
      });
      builder.addCase(addBrand.fulfilled, (state, action) => {
        state.loading = false;
      });
      builder.addCase(addBrand.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      });
    },
})

export const { resetBrandList } = brandSlice.actions

export const selectBrandList = (state: any) => {return state.brands.brandList};
export const selectAllBrands = (state: any) => {return state.brands.brands};
export const selectBrandLoading = (state: any) => {return state.brands.loading};

export default brandSlice.reducer