import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios';
import { Location } from '../../model/model';

const initialState = {
  sections: [],
  locations: [],
  selectedLocation: null as null | Location,
  loading: true,
  error: '',
}

export const getLocations = createAsyncThunk('locations/getlocations',async (organizationUuid:string) => {
  const res = await axios.get('/locations/list?organization_uuid='+organizationUuid);
  return res.data;
});


type CodeProps = {
  location_uuid: string,
  code: string,
}

export const postCode = createAsyncThunk('locations/postCode',async (prop:CodeProps) => {
  const res = await axios.post('/locations/freee', prop);
  return res.data;
});

type SectionProps = {
  location_uuid: string,
  section_id: string,
}

export const postSection = createAsyncThunk('locations/postSection',async (prop:SectionProps) => {
  const res = await axios.post('/locations/freee/section', prop);
  return res.data;
});

const locationsSlice = createSlice({
    name: 'locations',
    initialState,
    reducers: {
      setLocation: (state, action: PayloadAction<string>) => {
        var l = state.locations.filter((loc: Location) => loc.uuid == action.payload);
        if (l) {
          state.selectedLocation = l[0] as Location;
          localStorage.setItem("location_uuid", state.selectedLocation.uuid);
        }
      },
      resetLocations: (state) => {
        state.locations = [];
        localStorage.removeItem("location_uuid");
      },
    },
    extraReducers: (builder) => {
      builder.addCase(getLocations.pending, (state, action) => {
        state.loading = true;
      });
      builder.addCase(getLocations.fulfilled, (state, action) => {
        state.loading = false;
        state.locations = action.payload.data;
        if (state.locations.length > 0) {
          var id = localStorage.getItem("location_uuid");
          if (id) {
            var filtered = state.locations.filter((l: Location) => l.uuid == id);
            if (filtered.length > 0) {
              state.selectedLocation = filtered[0];
              return;
            }
          }
          state.selectedLocation = state.locations[0] as Location;
          localStorage.setItem("location_uuid", state.selectedLocation.uuid);
        }
      });
      builder.addCase(getLocations.rejected, (state, action) => {
        state.loading = false;
        state.error = 'failed';
        if (action.error.message) {
          state.error = action.error.message;
        }
      });

      builder.addCase(postCode.pending, (state, action) => {
        state.loading = true;
      });
      builder.addCase(postCode.fulfilled, (state, action) => {
        state.loading = false;
        state.sections = action.payload.data;
      });
      builder.addCase(postCode.rejected, (state, action) => {
        state.loading = false;
        state.error = 'failed';
        if (action.error.message) {
          state.error = action.error.message;
        }
      });

      builder.addCase(postSection.pending, (state, action) => {
        state.loading = true;
      });
      builder.addCase(postSection.fulfilled, (state, action) => {
        state.loading = false;
        state.selectedLocation = action.payload.data;
      });
      builder.addCase(postSection.rejected, (state, action) => {
        state.loading = false;
        state.error = 'failed';
        if (action.error.message) {
          state.error = action.error.message;
        }
      });
    },
})

export const { setLocation, resetLocations } = locationsSlice.actions

export const selectLocation = (state: any) => {return state.locations.selectedLocation};
export const selectLocations = (state: any) => {return state.locations.locations};
export const selectSections = (state: any) => {return state.locations.sections};
export const selectLocationLoading = (state: any) => {return state.locations.loading};
export const selectLocationError = (state: any) => {return state.locations.error};

export default locationsSlice.reducer