import React, { useEffect, forwardRef, useState } from 'react';
import { Organization, Location, BrandItem, Brand } from '../../model/model';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch } from '../../store/store';
import { Box, Button, CircularProgress, Grid, IconButton, MenuItem, Modal, Paper, Select, SelectChangeEvent, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, useTheme } from '@mui/material';
import { selectOrganization } from '../../store/modules/user';
import { getItems, getOptions, getItemSummary, getUnlinkItems, selectBrandItems, selectDateRange, selectIngSummary, selectItemSummary, selectPrepSummary, selectStockLoadings, getCompSummary } from '../../store/modules/stocks';
import { getLocations, selectLocation, selectLocationLoading, selectLocations, setLocation } from '../../store/modules/locations';
import { CalendarTodayOutlined } from '@mui/icons-material';
import dayjs, { Dayjs } from 'dayjs';
import DateSelector from '../DateSelector';
import { DateRange } from '@mui/x-date-pickers-pro';
import LinkItems from './LinkItems';

import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { DisplayNumber } from '../../pkg/pkg';
import { getAllBrands, selectAllBrands } from '../../store/modules/brands';

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number,
  ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const StockSummary = () => {
  const locationLoading: boolean = useSelector(selectLocationLoading);

  const dispatch = useDispatch<AppDispatch>();
  
  const organization: null | Organization = useSelector(selectOrganization);
  const locations: Location[] = useSelector(selectLocations);
  const location: null | Location = useSelector(selectLocation);
  const [locationUuid, setLocationUuid] = useState('');
  const brands: Brand[] = useSelector(selectAllBrands);
  const [brandUuid, setBrandUuid] = useState('all');

  const stockLoadings = useSelector(selectStockLoadings);
  const itemSummary = useSelector(selectItemSummary);
  const prepSummary = useSelector(selectPrepSummary);
  const ingSummary = useSelector(selectIngSummary);
  const brandItems: BrandItem[] = useSelector(selectBrandItems);

  // calendar
  const [showCalender, setShowCalendar] = useState(false);
  const [showLinking, setShowLinking] = useState(false);
  const dateRange = useSelector(selectDateRange);

  useEffect(() => {
    if (location && organization) {
      setLocationUuid(location.uuid);
      if (brandItems.length == 0) {
        dispatch(getItems(organization.uuid));
        dispatch(getOptions(organization.uuid));
      }
      if (!brands.length) {
        dispatch(getAllBrands(organization.uuid));
      }
    }
  }, [location, organization]);

  useEffect(() => {
    if (locationUuid && brandUuid && dateRange) {
      setShowCalendar(false);
      reloadSummaryData();
    }
  }, [locationUuid, brandUuid, dateRange]);

  const reloadSummaryData = () => {
    dispatch(getItemSummary({
      fromDate:dateRange[0]!.format('YYYY-MM-DD'),
      toDate: dateRange[1]!.format('YYYY-MM-DD'),
      locationUuid: location!.uuid,
      brandUuid: brandUuid == 'all' ? undefined : brandUuid,
      // ofoSlug: 'ubereats',
    }));
    dispatch(getUnlinkItems({
      fromDate:dateRange[0]!.format('YYYY-MM-DD'),
      toDate: dateRange[1]!.format('YYYY-MM-DD'),
      locationUuid: location!.uuid,
      brandUuid: brandUuid == 'all' ? undefined : brandUuid,
    }));
    dispatch(getCompSummary({
      fromDate:dateRange[0]!.format('YYYY-MM-DD'),
      toDate: dateRange[1]!.format('YYYY-MM-DD'),
      locationUuid: location!.uuid,
      brandUuid: brandUuid == 'all' ? undefined : brandUuid,
      // ofoSlug: 'ubereats',
    }));
  }

  // selection
  const handleSelectChange = (event: SelectChangeEvent) => {
    if (event.target.name == 'location-select') {
      dispatch(setLocation(event.target.value as string));
    } else if (event.target.name == 'brand-select') {
      setBrandUuid(event.target.value as string);
    }
  }

  const displayLocationSelect = () => {
    return (
      <Select
        sx={{width: '100%', maxWidth: '300px'}}
        labelId="location-select-label"
        id="location-select"
        value={locationUuid}
        label="Location"
        name='location-select'
        onChange={handleSelectChange}
      >
        {locations
          .map((l: Location) => (
            <MenuItem value={l.uuid} key={l.uuid}>{l.name}</MenuItem>
        ))}
      </Select>
    );
  }

  const displayBrandSelect = () => {
    return (
      <Select
        sx={{width: '100%', maxWidth: '300px'}}
        labelId="brand-select-label"
        id="brand-select"
        value={brandUuid}
        label="Brand"
        name='brand-select'
        onChange={handleSelectChange}
      >
        <MenuItem value={'all'} key={'all'}>{'全ブランド'}</MenuItem>
        {brands
          .map((b: Brand) => (
            <MenuItem value={b.uuid} key={b.uuid}>{b.name}</MenuItem>
        ))}
      </Select>
    );
  }

  // pagenation
  const [pages, setPages] = React.useState({
    pages: [0,0,0],
    rowsPerPages: [10,10,10],
    flag: false,
  });

  // display table
  const displayTable = (items: any, index: number) => {
    return (
      <TableContainer component={Paper}>
        <Table stickyHeader sx={{tableLayout: 'fixed'}} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell key='date' sx={{
                position: "sticky",
                left: 0,
                width: 150,
                background: "white",
                zIndex: 100,
                borderRight: 1,
              }}>Date</TableCell>
                {items.data.map((d:any) => (
                  <TableCell align="right" key={d.date} sx={{
                    width: 80,
                  }}>{d.date}</TableCell>
                ))}
              <TableCell key='total' sx={{
                position: "sticky",
                right: 0,
                width: 50,
                background: "white",
                zIndex: 100,
                borderLeft: 1,
              }}>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(pages.rowsPerPages[index] > 0
              ? items.total.slice(pages.pages[index] * pages.rowsPerPages[index], pages.pages[index] * pages.rowsPerPages[index] + pages.rowsPerPages[index])
              : items.total
            ).map((item:any) => (
              <TableRow key={item.uuid}>
                <TableCell key='name' component="th" scope="row" sx={{
                  position: "sticky",
                  left: 0,
                  width: 150,
                  background: "white",
                  zIndex: 100,
                  borderRight: 1,
                }}>
                  {item.name}
                </TableCell>
                {items.data.map((d:any) => (
                  <TableCell key={d.date} align="right">
                    {d.count_by_items.filter((i:any) => i.uuid == item.uuid).length > 0
                    ? DisplayNumber(d.count_by_items.filter((i:any) => i.uuid == item.uuid)[0].sales_count, d.count_by_items.filter((i:any) => i.uuid == item.uuid)[0].unit)
                    : 0 }
                  </TableCell>
                ))}
                <TableCell key='total' align="right" sx={{
                  position: "sticky",
                  right: 0,
                  background: "white",
                  zIndex: 100,
                  borderLeft: 1,
                }}>{DisplayNumber(item.sales_count, item.unit)}</TableCell>
              </TableRow>
            ))}
            {(pages.pages[index] > 0 && Math.max(0, (1 + pages.pages[index]) * pages.rowsPerPages[index] - items.total.length)) ? (
              <TableRow style={{ height: 53 * Math.max(0, (1 + pages.pages[index]) * pages.rowsPerPages[index] - items.total.length) }}>
                <TableCell colSpan={1} />
              </TableRow>
            ): <></>}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 25, { label: 'All', value: -1 }]}
                colSpan={3}
                count={items.total.length}
                rowsPerPage={pages.rowsPerPages[index]}
                page={pages.pages[index]}
                slotProps={{
                  select: {
                    inputProps: {
                      'aria-label': 'rows per page',
                    },
                    native: true,
                  },
                }}
                onPageChange={(
                  event: React.MouseEvent<HTMLButtonElement> | null,
                  newPage: number,
                ) => {
                  pages.pages[index] = newPage;
                  setPages({...pages, flag: true})
                }}
                onRowsPerPageChange={(
                  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
                ) => {
                  pages.rowsPerPages[index] = parseInt(event.target.value, 10);
                  pages.pages[index] = 0;
                  setPages({...pages, flag: true})
                }}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    );
  }

  return (
    <>
      {locationLoading ?
        <div style={{textAlign:'center'}}><CircularProgress /></div> :
        <Grid container>
          {/* location select */}
          <Grid item xs={6} md={4} sx={{height: '60px', padding: '0 10px'}}>
            {displayLocationSelect()}
          </Grid>
          <Grid item xs={6} md={4} sx={{height: '60px', padding: '0 10px'}}>
            {displayBrandSelect()}
          </Grid>
          {/* date select */}
          <Grid item xs={12} md={4} sx={{ height:'40px', lineHeight: '40px', textAlign: 'right' }}>
            <Button
              onClick={() => setShowCalendar(true)}
            >
              <Box sx={{height:'40px', lineHeight: '40px', mr: '10px'}}>
                {dateRange[0]?.format('M/D') + ' ~ ' + dateRange[1]?.format('M/D')}
              </Box>
              <CalendarTodayOutlined />
            </Button>
          </Grid>
          <Grid item xs={12}><h3>商品別出数</h3></Grid>
          <Grid item xs={12}><p>原価合計: {DisplayNumber(ingSummary.total.reduce((sum:number,i:any) => {return sum+i.total_cost},0), '円')}</p></Grid>
          { stockLoadings.itemSummary ? 
            <div style={{textAlign:'center'}}><CircularProgress /></div> :
            displayTable(itemSummary, 0)
          }
          <Grid item xs={12}>
            <Button variant="contained" onClick={() => setShowLinking(true)}>商品紐づけ</Button>
          </Grid>
          <Grid item xs={12}>
            <h3>仕込み別出数</h3>
          </Grid>
          <Grid item xs={12}>
          { stockLoadings.compSummary ? 
            <div style={{textAlign:'center'}}><CircularProgress /></div> :
            displayTable(prepSummary, 1)
          }
          </Grid>
          <Grid item xs={12}>
            <h3>材料別出数</h3>
          </Grid>
          <Grid item xs={12}>
          { stockLoadings.compSummary ? 
            <div style={{textAlign:'center'}}><CircularProgress /></div> :
            displayTable(ingSummary, 2)
          }
          </Grid>

          <Modal
            open={showCalender}
            onClose={() => setShowCalendar(false)}
            sx={{
              justifyContent: 'center',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <DateSelector />
          </Modal>

          <Modal
            open={showLinking}
            onClose={() => {
              setShowLinking(false);
              reloadSummaryData();
            }}
            sx={{
              justifyContent: 'center',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            { stockLoadings.unlinkItems ? 
              <div style={{textAlign:'center'}}><CircularProgress /></div> :
              <LinkItems />
            }
          </Modal>
        </Grid>
      }
    </>
  );
};

export default StockSummary;