import { useEffect, useState } from 'react';

import { Box, Collapse, Divider, Drawer, List, ListItemButton, ListItemIcon, ListItemText, Switch } from '@mui/material';
import { CategoryOutlined, ChevronLeft, ExpandLess, ExpandMore, HomeOutlined, Menu, MenuBookOutlined, StorefrontOutlined } from '@mui/icons-material/';
import LogoutIcon from '@mui/icons-material/Logout';
import { auth } from '../firebase';
import { onAuthStateChanged, signOut } from "firebase/auth";
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import { AppDispatch } from '../store/store';
import { useNavigate } from 'react-router-dom';

import { getOrganization, resetUser, selectOrganization, selectUser } from '../store/modules/user';
import { getLocations } from '../store/modules/locations';

const Sidenav = () => {
  const isDesktop: boolean = useMediaQuery({ minWidth: 600 })
	const [drawerOpened, setDrawerOpened] = useState(isDesktop ? true : false);
  const [listOpened, setListOpened] = useState(true);
  const user = useSelector(selectUser);
  const organization = useSelector(selectOrganization);

  const handleClick = () => {
    setListOpened(!listOpened);
  };

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const signout = async () => {
    await signOut(auth);
    dispatch(resetUser());
    localStorage.removeItem('token');
  }

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      let token = localStorage.getItem('token');
      if (!user || !token) {
        navigate('/signin');
      } else if (!organization) {
        dispatch(getOrganization());
      } else {
        dispatch(getLocations(organization.uuid));
      }
    });
  }, [organization]);

  return (
    <>
      {drawerOpened ? <></> :
        <Box sx={{
            height: '40px',
            width: '60px',
            backgroundColor: '#white',
            position: 'absolute'
          }}
          onClick={() => setDrawerOpened(true)}>
          <Menu style={{ width:'40px', height: '40px' }} />
        </Box>
      }
      <Drawer
        anchor={'left'}
        variant='persistent'
        open={drawerOpened}
        sx={{width: drawerOpened ? 180 : 0}}>
        <Box sx={{
            display: 'flex',
            justifyContent: 'end',
            height: '50px',
            backgroundColor: 'white',
          }}
          onClick={() => setDrawerOpened(false)}>
          <ChevronLeft style={{ width:'40px', height: '40px', margin: '5px' }} />
        </Box>
      <List sx={{ m: '0 20px'}}>
        <h2 style={{ width:'140px', fontSize: '20px', 'textAlign': 'center' }}>Allin<br />Records</h2>
        <p style={{  width:'140px', fontSize: '18px', 'textAlign': 'center'}}>{organization? organization.name: ''}</p>
      </List>
      <Divider />
      <List>
        <ListItemButton
          onClick={(event) => {
            event.stopPropagation();
            navigate('/home');
          }}
          key='home'
        >
          <ListItemIcon><HomeOutlined /></ListItemIcon>
          <ListItemText primary="Home" />
        </ListItemButton>
      </List>
      <List>
        <ListItemButton
          onClick={(event) => {
            event.stopPropagation();
            navigate('/credentials');
          }}
          key='credentials'
        >
          <ListItemIcon><StorefrontOutlined /></ListItemIcon>
          <ListItemText primary="Credentials" />
        </ListItemButton>
      </List>
      <List>
        <ListItemButton
          onClick={(event) => {
            event.stopPropagation();
            navigate('/menu');
          }}
          key='menu'
        >
          <ListItemIcon><MenuBookOutlined /></ListItemIcon>
          <ListItemText primary="Menu" />
        </ListItemButton>
      </List>
      <List>
        <ListItemButton
          onClick={(event) => {
            event.stopPropagation();
            navigate('/stock');
          }}
          key='stock'
        >
          <ListItemIcon><CategoryOutlined /></ListItemIcon>
          <ListItemText primary="Stock" />
        </ListItemButton>
      </List>
      <List>
        <ListItemButton
          onClick={(event) => {
            event.stopPropagation();
            signout();
          }}
          key='signout'
        >
          <ListItemIcon><LogoutIcon /></ListItemIcon>
          <ListItemText primary="Signout" />
        </ListItemButton>
      </List>
      </Drawer>
    </>
  )
}

export default Sidenav;