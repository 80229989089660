

export const PriceFormat = (num: number, disit: number) => {
    var s = String(num).split('.');
    var ret = String(s[0]).replace( /(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
    if (s.length > 1) {
        ret += '.' + s[1];
    }
    return ((' '.repeat(disit)) + ret).slice(-disit);
  }

export const DisplayNumber = (number: number, unit: string) => {
    return (number > 100 ? Math.round(number) : Math.round(number * 100) / 100).toLocaleString() + unit;
}