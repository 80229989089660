import Sidenav from '../components/Sidenav';
import CredentialView, { Props } from '../components/CredentialView'
import { AppDispatch } from '../store/store';
import { Brand, Credential, Location, Organization } from '../model/model';
import { getLocations, setLocation, selectLocations, selectLocationLoading, selectLocation } from '../store/modules/locations';
import { selectStores, selectStoreLoading, getStoreList } from '../store/modules/stores';

import { useEffect, useRef, useState, forwardRef } from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Drawer from '@mui/material/Drawer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress'
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button, MenuItem, Select, SelectChangeEvent, TableCell } from '@mui/material';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';

import ubereats from '../assets/ubereats.png'
import demaecan from '../assets/demaecan.png'
import menu from '../assets/menu.png'
import wolt from '../assets/wolt.png'
import square from '../assets/square.png'
import takeme from '../assets/takeme.png'
import { AddOutlined, AddBoxOutlined, WarningOutlined } from '@mui/icons-material';
import { resetCredential, selectCredentialLoading, selectCredentialFinished, setCredential } from '../store/modules/credentials';
import { disconnectFreee, selectOrganization, selectUserLoading } from '../store/modules/user';

const img = (slug:string) => {
  switch(slug) {
    case 'ubereats': return ubereats;
    case 'demaecan': return demaecan;
    case 'menu': return menu;
    case 'wolt': return wolt;
    case 'square': return square;
    case 'takeme': return takeme;
    default: return '';
  }
}

const Credentials = () => {
  const locations: Location[] = useSelector(selectLocations);
  const location: Location = useSelector(selectLocation);
  const brands: Brand[] = useSelector(selectStores);
  const organization: Organization = useSelector(selectOrganization);

  const storeLoading: boolean = useSelector(selectStoreLoading);
  const credentialLoading: boolean = useSelector(selectCredentialLoading);
  const credentialFinished: boolean = useSelector(selectCredentialFinished);
  const locationLoading: boolean = useSelector(selectLocationLoading);
  const userLoading: boolean = useSelector(selectUserLoading);

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  useEffect(() => {
    if (location) {
      dispatch(getStoreList(location.uuid));
    }
  }, [location]);

  useEffect(() => {
    if (credentialFinished && showModal) {
      modalClose();
      dispatch(getStoreList(location.uuid));
    }
  }, [credentialFinished]);

  const handleLocationChange = (event: SelectChangeEvent) => {
    dispatch(setLocation(event.target.value as string));
  }

  const [showModal, setShowModal] = useState(false);
  const [brand, setBrand] = useState(null as Brand | null);
  const [storeUUid, setStoreUuid] = useState('');

  const modalOpen = (brand: Brand, cred:Credential) => {
    setBrand(brand);
    dispatch(setCredential(cred));
    setShowModal(true);
  };

  const modalClose = () => {
    setShowModal(false);
    setBrand(new Brand());
    dispatch(resetCredential());
  }

  

  const Ref = forwardRef<HTMLDivElement, Props>(
    ({brand}, ref) => {
      return <CredentialView brand={brand} forwardRef={ref} />;
    }
  );

  return (
    <>
      <Sidenav />
      <Grid container sx={{ pt:'40px', m:'0 auto', overflow: 'hidden'}}>
        {( userLoading || storeLoading || locationLoading) ?
          <div style={{textAlign:'center'}}><CircularProgress /></div> :
          <>
            <Grid item xs={6} sx={{height: '60px'}}>
              <Select
                sx={{width: '100%', maxWidth: '300px'}}
                labelId="location-select-label"
                id="location-select"
                value={location?.uuid}
                label="Location"
                onChange={handleLocationChange}
              >
                {locations
                  .map((l: Location) => (
                    <MenuItem value={l.uuid} key={l.uuid}>{l.name}</MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={6} sx={{display: 'flex', flexFlow: 'row-reverse'}}>
              { organization?.freee_active ? 
                <Button onClick={() => navigate('/freee')}>Freee Settings</Button> :
                <Button href='https://accounts.secure.freee.co.jp/public_api/authorize?client_id=1307b0e35caabb461836f8ab7738c47118a7caba49980684026eaa55a3d52c83&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Ffreee&response_type=code&prompt=select_company'>Connect Freee</Button>
              }
            </Grid>
            <Grid item xs={12} sx={{
              display: 'flex',
              justifyContent: 'center',
              height: 'calc(100vh - 100px)',
              pt: '20px',
              pb: '40px',
              overflowY: 'auto',
            }}>
              <TableContainer component={Paper} sx={{
                bgcolor: 'transparent',
                width: '100%',
                maxWidth: '1000px',
              }}>
                <Table aria-label='simple table'>
                  <TableBody>
                    { brands.map((brand:Brand) => (
                      <TableRow
                        sx={{m: '10px auto', display: 'flex'}}
                        key={brand.uuid}
                      >
                        <TableCell sx={{
                          p:'0',
                          width: '100%',
                          background: 'white',
                          border:'1px solid black',
                          borderRadius: '4px',
                          overflow: 'hidden'
                        }}>
                          <Grid container sx={{p: {xs: '5px 10px', sm: '10px 20px'}}}>
                            <Grid item xs={12} sm={6}>
                              <Grid container sx={{
                                width: '100%',
                                justifyContent: 'center',
                              }}>
                                <Grid item xs={10} sx={{fontSize: '18px', m: 'auto'}}>{brand.name}</Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Grid container sx={{
                                width: '100%',
                                height: '40px',
                                justifyContent: 'right',
                              }}>
                                {brand.credentials.map((cred: Credential) => (
                                  <Grid item sx={{position: 'relative', width:'40px', height: '40px', mr: '10px'}} onClick={() => modalOpen(brand, cred)}>
                                    <img src={img(cred.ofo)} style={{ width:'40px', height: '40px', borderRadius: '10%' }} />
                                    { (cred.app?.status == 'INVALID_CREDENTIAL' || cred.app?.status == 'ERROR' || cred.web?.status == 'INVALID_CREDENTIAL' || cred.web?.status == 'ERROR') ? 
                                      <WarningOutlined color='error' style={{ position: 'absolute', right: '-8px', top: '-8px', width:'20px', height: '20px' }} />
                                      : (cred.app?.status == 'INACTIVE' || cred.web?.status == 'INACTIVE') ? 
                                      <WarningOutlined color='warning' style={{ position: 'absolute', right: '-8px', top: '-8px', width:'20px', height: '20px' }} />
                                      : <></>
                                    }
                                  </Grid>
                                ))}
                                  <Grid item sx={{position: 'relative'}} onClick={() => modalOpen(brand, new Credential())}>
                                    <AddBoxOutlined style={{ width:'40px', height: '40px' }} />
                                  </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow
                        sx={{m: '10px auto', display: 'flex'}}
                        key='new'
                      >
                        <TableCell sx={{
                          p:'0',
                          width: '100%',
                          borderRadius: '4px',
                          overflow: 'hidden',
                          textAlign: 'center'
                        }}>
                          <Grid container>
                            <Grid item xs={12} sx={{
                              background: '#F29C97',
                            }} onClick={() => {
                              setShowModal(true);
                            }}>
                              <AddOutlined sx={{ m: 'auto' }} />
                            </Grid>
                          </Grid>
                        </TableCell>
                      </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </>
        }
      </Grid>
      <Modal
        open={showModal}
        onClose={() => modalClose()}
        sx={{
          justifyContent: 'center',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Ref brand={brand} />
      </Modal>
    </>
  );
};

export default Credentials;