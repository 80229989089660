import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';

const initialState = {
  stores: [],
  loading: false,
  error: false,
}

export const getStoreList = createAsyncThunk('store/getStoreList', async (locationUuid:string) => {
  const res = await axios.get('/stores?location_uuid='+locationUuid);
  return res.data;
});

const storeSlice = createSlice({
    name: 'stores',
    initialState,
    reducers: {
      resetStores: (state) => {
        state.stores = [];
      },
    },
    extraReducers: (builder) => {
      builder.addCase(getStoreList.pending, (state, action) => {
        state.loading = true;
      });
      builder.addCase(getStoreList.fulfilled, (state, action) => {
        state.loading = false;
        state.stores = action.payload.data;
      });
      builder.addCase(getStoreList.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      });
    },
})

export const { resetStores } = storeSlice.actions

export const selectStores = (state: any) => {return state.stores.stores};
export const selectStoreLoading = (state: any) => {return state.stores.loading};

export default storeSlice.reducer