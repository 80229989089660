import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios';
import { Item, ItemSection, Option, Optiongroup } from '../../model/model';

const initialState = {
  items: [] as Item[],
  itemSections: [] as ItemSection[],
  itemSectionOpens: [] as boolean[],
  optiongroups: [] as Optiongroup[],
  optiongroupsOpens: [] as boolean[],
  options: [] as Option[],
  loadings: {
    item: false,
    option: false,
    items: false,
  },
  error: false,
}

export const getAllItems = createAsyncThunk('items/getAllItems', async (organizationUuid:string) => {
  const res = await axios.get('/items/all?organization_uuid='+organizationUuid);
  return res.data;
});

export const getSectionItems = createAsyncThunk('items/getSectionItems', async (locationUuid:string) => {
  const res = await axios.get('/items/sectionitems?location_uuid='+locationUuid);
  return res.data;
});

export const getOptiongroups = createAsyncThunk('items/getOptiongroups', async (locationUuid:string) => {
  const res = await axios.get('/items/optiongroups?location_uuid='+locationUuid);
  return res.data;
});

type PostProps = {
  path: string,
  SectionProps?: SectionProps | null,
  ItemInput?: ItemInput | null,
  OptiongroupProps?: OptiongroupProps | null,
  OptionProps?: OptionProps | null,
}

type SectionProps = {
  uuid: string | null,
  name: string | null,
  row: number,
  location_uuid: string,
}

type ItemInput = {
  itemProps: ItemProps,
  image: string | null,
}

type ItemProps = {
  uuid: string | null,
  brand_uuid: string | null,
  name: string,
  takeout_price: number,
  delivery_price: number,
  tax_rate: number,
  section_uuid: string | null,
  row: number | null,
  organization_uuid: string,
  section_item_uuid: string | null,
  display_type: string | null,
  add_optiongroup_uuids: string[],
  remove_optiongroup_uuids: string[]
}

type OptiongroupProps = {
  uuid: string | null,
  location_uuid: string,
  name: string,
  row: number | null,
  is_required: boolean,
  min_selection: number | null,
  max_selection: number | null,
  add_item_uuids: string[],
  remove_item_uuids: string[]
}

type OptionProps = {
  uuid: string | null,
  name: string,
  takeout_price: number,
  delivery_price: number,
  tax_rate: number,
  optiongroup_uuid: string | null,
  optiongroup_option_uuid: string | null,
  unique_takeout_price: number | null,
  unique_delivery_price: number | null,
  row: number | null,
  organization_uuid: string,
}

type ConnectProps = {
  uuid: string,
  section_uuid: string,
  row: number,
}

type DeleteProps = {
  type: string,
  uuid: string,
}

export const postItem = createAsyncThunk('items/postItem', async (prop:PostProps) => {
  const body =
    prop.path == 'section' ? prop.SectionProps :
    prop.path == 'item' ? prop.ItemInput?.itemProps :
    prop.path == 'optiongroup' ? prop.OptiongroupProps :
    prop.path == 'option' ? prop.OptionProps : null;
  
  const res = await axios.post('/items/'+prop.path, body, );
  if (prop.path =='item' && prop.ItemInput?.image) {
    const imageRes = await axios.post('/items/image', {
      uuid: res.data.uuid,
      image: prop.ItemInput.image,
    });
    return imageRes.data;
  }
  return res.data;
});

export const connectItem = createAsyncThunk('items/connectItem', async (prop:ConnectProps) => {  
  const res = await axios.post('/items/connect', prop);
  return res.data;
});

export const deleteItem = createAsyncThunk('items/deleteItem', async (prop:DeleteProps) => {
  await axios.delete('/items?uuid='+prop.uuid+'&type='+prop.type);
  return prop;
});

export const postItemRow = createAsyncThunk('items/postItemRow', async (param:{type: string, uuid:string; row: number}[]) => {
  const res = await axios.post('/items/row', {data: param});
  return res.data;
});

const itemSlice = createSlice({
    name: 'items',
    initialState,
    reducers: {
      resetItems: (state) => {
        state.items = [];
        state.itemSections = [];
        state.itemSectionOpens = [];
        state.optiongroups = [];
        state.optiongroupsOpens = [];
      },
      setItemSectionOpens: (state, action: PayloadAction<number>) => {
        state.itemSectionOpens[action.payload] = !state.itemSectionOpens[action.payload];
      },
      resetItemSectionOpens: (state) => {
        state.itemSectionOpens = Array(state.itemSections.length).fill(false);
      },
      setOptiongroupOpens: (state, action: PayloadAction<number>) => {
        state.optiongroupsOpens[action.payload] = !state.optiongroupsOpens[action.payload];
      },
      resetOptiongroupOpens: (state) => {
        state.optiongroupsOpens = Array(state.optiongroups.length).fill(false);
      },
      setNewObject: (state, action: PayloadAction<{
        index?: number;
        sections?: ItemSection[];
        items?: Item[];
        optiongroups?: Optiongroup[];
        options?: Option[];
      }>) => {
        const {index, sections, items, optiongroups, options} = action.payload;
        if (sections) {
          state.itemSections = sections;
        } else if (items) {
          state.itemSections[index!].items = items;
        } else if (optiongroups) {
          state.optiongroups = optiongroups;
        } else if (options) {
          state.optiongroups[index!].options = options;
        }
      },
      setOptiongroup: (state, action: PayloadAction<{
        index: number;
        optiongroup: Optiongroup
      }>) => {
        state.optiongroups[action.payload.index] = action.payload.optiongroup;
      },
    },
    extraReducers: (builder) => {
      builder.addCase(getAllItems.pending, (state, action) => {
        state.loadings.items = true;
      });
      builder.addCase(getAllItems.fulfilled, (state, action) => {
        state.loadings.items = false;
        state.items = action.payload.data;
      });
      builder.addCase(getAllItems.rejected, (state, action) => {
        state.loadings.items = false;
        state.error = true;
      });
      builder.addCase(getSectionItems.pending, (state, action) => {
        state.loadings.item = true;
      });
      builder.addCase(getSectionItems.fulfilled, (state, action) => {
        state.loadings.item = false;
        state.itemSections = action.payload.data;
        state.itemSectionOpens = Array(state.itemSections.length).fill(false);
      });
      builder.addCase(getSectionItems.rejected, (state, action) => {
        state.loadings.item = false;
        state.error = true;
      });
      builder.addCase(getOptiongroups.pending, (state, action) => {
        state.loadings.option = true;
      });
      builder.addCase(getOptiongroups.fulfilled, (state, action) => {
        state.loadings.option = false;
        state.optiongroups = action.payload.data;
        if (state.optiongroupsOpens.length != action.payload.data.length) {
          state.optiongroupsOpens = Array(action.payload.data.length).fill(false);;
        }
      });
      builder.addCase(getOptiongroups.rejected, (state, action) => {
        state.loadings.option = false;
        state.error = true;
      });
      builder.addCase(postItem.pending, (state, action) => {
        state.loadings.item = true;
        state.loadings.option = true;
      });
      builder.addCase(postItem.fulfilled, (state, action) => {
        if (action.payload.data) {
          if (action.payload.data.section) {
            if (state.itemSections.length < action.payload.data.section.row) {
              state.itemSections.push(new ItemSection(action.payload.data.section));
            } else {
              state.itemSections[action.payload.data.section.row-1] = {
                ...state.itemSections[action.payload.data.section.row-1], 
                name: action.payload.data.section.name,
                row: action.payload.data.section.row,
              }
            }
          } else if (action.payload.data.item) {
            for (var i in state.items) {
              if (state.items[i].uuid == action.payload.data.item.uuid) {
                state.items[i] = action.payload.data.item;
                break;
              }
            }
            if (action.payload.data.section_uuid) {
              for (var i in state.itemSections) {
                if (state.itemSections[i].uuid == action.payload.data.section_uuid) {
                  state.itemSections[i].items.push(
                    {...action.payload.data.item, row: state.itemSections[i].items.length + 1}
                  )
                }
              }
            }
          } else if (action.payload.data.optiongroup) {
            if (state.optiongroups.length < action.payload.data.optiongroup.row) {
              state.optiongroups.push(action.payload.data.optiongroup);
            } else {
              state.optiongroups[action.payload.data.optiongroup.row-1] = {
                ...state.optiongroups[action.payload.data.optiongroup.row-1], 
                name: action.payload.data.optiongroup.name,
                row: action.payload.data.optiongroup.row,
              }
            }
          }
        }
        state.loadings.item = false;
        state.loadings.option = false;
      });
      builder.addCase(postItem.rejected, (state, action) => {
        state.loadings.item = false;
        state.loadings.option = false;
        state.error = true;
      });
      builder.addCase(connectItem.pending, (state, action) => {
        state.loadings.item = true;
      });
      builder.addCase(connectItem.fulfilled, (state, action) => {
        for (var i in state.itemSections) {
          if (state.itemSections[i].uuid == action.payload.data.section_uuid) {
            state.itemSections[i].items.push(action.payload.data);
          }
        }
        state.loadings.item = false;
      });
      builder.addCase(connectItem.rejected, (state, action) => {
        state.loadings.item = false;
        state.error = true;
      });
      builder.addCase(deleteItem.pending, (state, action) => {
        state.loadings.item = true;
      });
      builder.addCase(deleteItem.fulfilled, (state, action) => {
        state.loadings.item = false;
        if (action.payload.type == 'section') {
          var deleteRow = 999
          for (var i in state.itemSections) {
            if (state.itemSections[i].uuid == action.payload.uuid) {
              deleteRow = Number(i);
            }
            if (Number(i) > deleteRow) {
              state.itemSections[i].row -= 1;
            }
          }
          state.itemSections.splice(deleteRow,1);
        }
      });
      builder.addCase(deleteItem.rejected, (state, action) => {
        state.loadings.item = false;
        state.error = true;
      });
    },
})

export const { setItemSectionOpens, resetItemSectionOpens, setOptiongroupOpens, resetOptiongroupOpens, resetItems, setNewObject } = itemSlice.actions

export const selectAllItems = (state: any) => {return state.items.items};
export const selectItemSections = (state: any) => {return state.items.itemSections};
export const selectItemSectionOpens = (state: any) => {return state.items.itemSectionOpens};
export const selectOptiongroups = (state: any) => {return state.items.optiongroups};
export const selectOptiongroupOpens = (state: any) => {return state.items.optiongroupsOpens};
export const selectItemLoading = (state: any) => {return state.items.loadings.item};
export const selectOptionLoading = (state: any) => {return state.items.loadings.option};
export const selectItemsLoading = (state: any) => {return state.items.loadings.items};

export default itemSlice.reducer