import Sidenav from '../components/Sidenav';

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../store/store';
import { Box, Grid } from '@mui/material';

const Home = () => {
  const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      let token = localStorage.getItem('token');
      if (!user || !token) {
        navigate('/signin');
      }
    });
  }, []);

  return (
    <>
      <Sidenav />
      <Grid container mb='10px'>
          <Grid item xs={12} sx={{m: '10px'}}>
            <h3>テスト版です。</h3>
            <p>Accounts: デリバリー連携</p>
            <p>Menu: テイクアウトメニューの作成</p>
            <p>Stock: 原価・在庫管理</p>
          </Grid>
      </Grid>
    </>
  );
};

export default Home;