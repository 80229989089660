import { useEffect, useState, forwardRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Draggable from 'react-draggable';

import { Button, CircularProgress, Collapse, List, ListItemButton, ListItemText, Paper, Grid, Modal, Table, TableBody, TableContainer, TableRow, TableCell } from '@mui/material';
import { AddOutlined, DragHandleOutlined, ExpandLess, ExpandMore } from '@mui/icons-material';

import { Option, Optiongroup } from '../../model/model';
import { AppDispatch } from '../../store/store';
import { selectOptiongroups, selectOptiongroupOpens, selectOptionLoading, postItemRow, setNewObject, setOptiongroupOpens, resetOptiongroupOpens } from '../../store/modules/items';

import MenuItemView, { Props } from './MenuItemView';

const MenuOptions = () => {
  const optiongroups: Optiongroup[] = useSelector(selectOptiongroups);
  const optiongroupOpens: boolean[] = useSelector(selectOptiongroupOpens);
  const optionLoading: boolean = useSelector(selectOptionLoading);

  useEffect(() => {
    if (!optionLoading && showModal) {
      setShowModal(false);
    }
  }, [optionLoading]);

  const [showModal, setShowModal] = useState(false);
  const [path, setPath] = useState('');
  const [optiongroup, setOptiongroup] = useState(new Optiongroup());
  const [option, setOption] = useState(new Option());
  const onClickElement = (optiongroup?: Optiongroup, option?: Option ) => {
    if (option) {
      setPath('option');
      if (optiongroup) {
        setOptiongroup(optiongroup)
      }
      setOption(option);
    } else if (optiongroup) {
      setPath('optiongroup');
      setOptiongroup(optiongroup);
    } else  {
      return;
    }
    setShowModal(true);
  }

  const itemHeight = 50;
  const ogHeight = itemHeight + 12;
  const [dragIndex, setDragIndex] = useState(0);
  const dispatch = useDispatch<AppDispatch>();
  const renderOption = (item: Option, opIndex: number, ogIndex: number) => {
    let selectedSection = optiongroups[ogIndex];
    if (!selectedSection) {
      return null;
    }
    return (
      <Draggable
        key={item.uuid}
        handle=".handle"
        axis="y"
        defaultPosition={{x: 0, y: 0}}
        position={{x:0, y: 0}}
        bounds={{top: -opIndex*itemHeight, bottom: (selectedSection.options.length-opIndex)*itemHeight}}
        scale={1}
        onDrag={(_, position) => {
          const y = position.y;
          let newIndex = Math.round(y / itemHeight);
          if (dragIndex != newIndex) {
            setDragIndex(newIndex);
          }
        }}
        onStop={() => {
          if (dragIndex != 0) {
            let curRow = item.row;
            let newRow = item.row + dragIndex;
            let setItems = [];
            let params = [];
            console.log(selectedSection.name)
            for (var it of selectedSection.options) {
              if (it.row == curRow) {
                setItems.push({...it, row: newRow});
                params.push({type: 'option', uuid: it.optiongroup_option_uuid!, row: newRow});
              } else if (it.row > curRow && it.row <= newRow) {
                setItems.push({...it, row: it.row - 1});
                params.push({type: 'option', uuid: it.optiongroup_option_uuid!, row:it.row - 1});
              } else if (it.row < curRow && it.row >= newRow) {
                setItems.push({...it, row: it.row + 1});
                params.push({type: 'option', uuid: it.optiongroup_option_uuid!, row:it.row + 1});
              } else {
                setItems.push(it);
              }
            }
            setItems.sort((i, j) => i.row - j.row);
            dispatch(setNewObject({index: ogIndex, options: setItems}));
            dispatch(postItemRow(params));
          }
        }}
      >
        <List component="div" disablePadding>
          <Grid container>
            <Grid item className="handle" xs='auto' sx={{height: itemHeight+'px', width: itemHeight+'px !important', textAlign: 'center'}}>
              <DragHandleOutlined sx={{height: itemHeight+'px'}} />
            </Grid>
            <Grid item xs>
              <ListItemButton sx={{ p:0, pr:'20px', height: itemHeight+'px' }} onClick={() => onClickElement(optiongroup, item)}>
                <Grid item xs={8}>
                  <ListItemText primary={item.name} />
                </Grid>
                <Grid item xs={2} sx={{textAlign: 'right'}}>
                  <ListItemText primary={item.takeout_price} />
                </Grid>
                <Grid item xs={2} sx={{textAlign: 'right'}}>
                  <ListItemText primary={item.delivery_price} />
                </Grid>
              </ListItemButton>
            </Grid>
          </Grid>
        </List>
      </Draggable>
    )
  }

  const renderOptiongroup = (section: Optiongroup, ogIndex: number) => {
    return (
      <Draggable
        key={section.uuid}
        handle=".oghandle"
        axis="y"
        defaultPosition={{x: 0, y: 0}}
        position={{x:0, y: 0}}
        bounds={{top: -ogIndex*ogHeight, bottom: (optiongroups.length-ogIndex-1)*ogHeight}}
        scale={1}
        onDrag={(_, position) => {
          dispatch(resetOptiongroupOpens());
          const y = position.y;
          let newIndex = Math.round(y / ogHeight);
          if (dragIndex != newIndex) {
            setDragIndex(newIndex);
          }
        }}
        onStop={() => {
          if (dragIndex != 0) {
            let curRow = section.row;
            let newRow = section.row + dragIndex;
            let setItems = [];
            let params = [];
            for (var og of optiongroups) {
              if (og.row == curRow) {
                setItems.push({...og, row: newRow});
                params.push({type: 'optiongroup', uuid: og.uuid!, row: newRow});
              } else if (og.row > curRow && og.row <= newRow) {
                setItems.push({...og, row: og.row - 1});
                params.push({type: 'optiongroup', uuid: og.uuid!, row:og.row - 1});
              } else if (og.row < curRow && og.row >= newRow) {
                setItems.push({...og, row: og.row + 1});
                params.push({type: 'optiongroup', uuid: og.uuid!, row:og.row + 1});
              } else {
                setItems.push(og);
              }
            }
            setItems.sort((i, j) => i.row - j.row);
            dispatch(setNewObject({index: ogIndex, optiongroups: setItems}));
            dispatch(postItemRow(params));
          }
        }}
      >
        <TableRow
          sx={{m: '10px auto', display: 'flex'}}
          key={section.uuid}
        >
          <TableCell sx={{
            p:'0',
            width: '100%',
            background: 'white',
            border:'1px solid black',
            borderRadius: '4px',
            overflow: 'hidden'
          }}>
            <Grid container>
              <Grid item className="oghandle" xs='auto' sx={{height: itemHeight+'px', width: itemHeight+'px !important', textAlign: 'center'}}>
                <DragHandleOutlined sx={{height: itemHeight+'px'}} />
              </Grid>
              <ListItemButton>
                <ListItemText primary={section.name} onClick={() => onClickElement(section)}/>
              </ListItemButton>
              <Button color='secondary' onClick={() => dispatch(setOptiongroupOpens(ogIndex))}>
                { optiongroupOpens[ogIndex] ? <ExpandLess /> : <ExpandMore /> }
              </Button>
            </Grid>
            <Collapse in={optiongroupOpens[ogIndex]} timeout="auto" unmountOnExit>
              { section.options.map((item: Option, opIndex: number) => renderOption(item, opIndex, ogIndex)) }
              <ListItemButton sx={{ background: '#F29C97' }} onClick={() => onClickElement(section, new Option())}>
                <AddOutlined sx={{ m: 'auto' }} />
              </ListItemButton>
            </Collapse>
          </TableCell>
        </TableRow>
      </Draggable>
    );
  }

  const Ref = forwardRef<HTMLDivElement, Props>(
    ({ path, section, item, optiongroup, option }, ref) => {
      return <MenuItemView
        children={{
          path: path,
          section: section,
          item: item,
          optiongroup: optiongroup,
          option: option,
        }}
        forwardRef={ref}
      />;
    }
  );

  return (
    <>
      <Grid item xs={12} sx={{
        display: 'flex',
        justifyContent: 'center',
        height: 'calc(100vh - 140px)',
        paddingTop: '20px',
        overflowY: 'auto',
      }}>
        {optionLoading ?
          <div style={{textAlign:'center'}}><CircularProgress /></div> :
          <TableContainer component={Paper} sx={{
            bgcolor: 'transparent',
            width: '100%',
            maxWidth: '1000px',
          }}>
            <Table aria-label='simple table'>
              <TableBody>
                  { optiongroups
                    .map((optiongroup: Optiongroup, index: number) => renderOptiongroup(optiongroup, index))
                  }
                <TableRow
                  sx={{m: '10px auto', display: 'flex'}}
                  key='new'
                >
                  <TableCell sx={{
                    p:'0',
                    width: '100%',
                    background: '#E84566',
                    borderRadius: '4px',
                    overflow: 'hidden',
                  }}>
                    <ListItemButton onClick={() => onClickElement(new Optiongroup({row: optiongroups.length + 1}))}>
                      <AddOutlined sx={{ m: 'auto' }} />
                    </ListItemButton>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        }
      </Grid>
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        sx={{
          justifyContent: 'center',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Ref 
          path={path}
          section={null}
          item={null}
          optiongroup={optiongroup}
          option={option}
        />
      </Modal>
    </>
  );
};

export default MenuOptions;