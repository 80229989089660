import { Record } from 'immutable';

export class Brand extends Record({
  uuid: '',
  name: '',
  store_uuid: '',
  freee_item_id: '',
  credentials: [],
}) {
}

export class Store extends Record({
  id: '',
  name: '',
}) {
}

export class Credential extends Record<{
  ofo: string;
  app : {
    code: null | string;
    id: null | string;
    password: null | string;
    status: null | string;
  },
  web: {
    code: null | string;
    id: null | string;
    password: null | string;
    status: string;
  },
  store: {
    name: string;
    url: null | string;
  },
}>({
  ofo: '',
  app: {
    code: null,
    id: null,
    password: null,
    status: 'INACTIVE',
  },
  web: {
    code: null,
    id: null,
    password: null,
    status: 'INACTIVE',
  },
  store: {
    name: '',
    url: null,
  },
}) {
}

export class CredentialInput extends Record<{
  store_uuid: string;
  ofo_slug: string;
  credential_input: {
      app_code: null | string;
      app_email: null | string;
      app_password: null | string;
      web_code: null | string;
      web_email: null | string;
      web_password: null | string;
      store_url: null | string;
  };
}>({
  store_uuid: '',
  ofo_slug: '',
  credential_input: {
    app_code: null,
    app_email: null,
    app_password: null,
    web_code: null,
    web_email: null,
    web_password: null,
    store_url: null,
  }
}) {
}

export class SelectInput extends Record({
  store_uuid: '',
  ofo_slug: '',
  store: new Store(),
}) {
}

export class Location extends Record({
  uuid: '',
  name: '',
  freee_section_id: '',
}) {
}

export class FreeeSection extends Record({
  id: 0,
  name: '',
}) {
}

export class FreeeItem extends Record({
  id: 0,
  name: '',
}) {
}

export class Organization extends Record({
  uuid: '',
  name: '',
  freee_active: false,
  freee_auth_code: null,
  freee_access_token: null,
  freee_refresh_token: null,
}) {
}

export class Option extends Record<{
  uuid: string | null;
  name: string;
  takeout_price: number;
  delivery_price: number;
  tax_rate: number;
  row: number;
  optiongroup_option_uuid: string | null;
}>({
  uuid: null,
  name: '',
  takeout_price: 0,
  delivery_price: 0,
  tax_rate: 0.08,
  row: 0,
  optiongroup_option_uuid: null,
}) {
}

export class Optiongroup extends Record<{
  uuid: string | null;
  name: string;
  row: number,
  is_required: boolean;
  min_selection: number | null;
  max_selection: number | null;
  options: Option[];
  item_uuids: string[];
}>({
  uuid: null,
  name: '',
  row: 0,
  is_required: false,
  min_selection: null,
  max_selection: null,
  options: [],
  item_uuids: [],
}) {
}

export class Item extends Record<{
  section_item_uuid: string | null,
  brand_uuid: string | null;
  uuid: string | null;
  name: string;
  name_managed: string | null;
  takeout_price: number;
  delivery_price: number;
  tax_rate: number;
  image_url: string | null;
  row: number;
  display_type: string | null;
  optiongroup_uuids: string[];
}>({
  section_item_uuid: null,
  brand_uuid: null,
  uuid: null,
  name: '',
  name_managed: null,
  takeout_price: 0,
  delivery_price: 0,
  tax_rate: 0.08,
  image_url: null,
  row: 0,
  display_type: null,
  optiongroup_uuids: [],
}) {
}

export class ItemSection extends Record<{
  uuid: string | null;
  name: string;
  row: number,
  items: Item[];
}>({
  uuid: null,
  name: '',
  row: 0,
  items: [],
}) {
}

export class StockIngredient extends Record<{
  uuid: string | null;
  name: string;
  category_uuid: string | null;
  price: number;
  stock_quantity: number;
  stock_unit: string;
  stock_price: number;
  unit_quantity: number;
  unit: string;
  unit_price: number;
  tax_rate: number;
  row: number;
}>({
  uuid: null,
  name: '',
  category_uuid: null,
  price: 0,
  stock_quantity: 0,
  stock_unit: '',
  stock_price: 0,
  unit_quantity: 0,
  unit: '',
  unit_price: 0,
  tax_rate: 0.08,
  row: 0,
}) {
}

export class StockCategory extends Record<{
  uuid: string;
  name: string;
  row: number;
}>({
  uuid: '',
  name: '',
  row: 0,
}) {}

export class StockComponent extends Record<{
  type: string;
  uuid: string;
  name: string;
  unit: string;
  unit_cost: number;
  quantity: number;
  total_cost: number;
}>({
  type: '',
  uuid: '',
  name: '',
  unit: '',
  unit_cost: 0,
  quantity: 0,
  total_cost: 0
}) {}

export class StockPreparation extends Record<{
  uuid: string| null;
  name: string;
  total_cost: number;
  volume: number;
  unit: string;
  unit_cost: number;
  stock_quantity: number;
  stock_unit: string;
  stock_cost: number;
  row: number;
  components: StockComponent[];
}>({
  uuid: null,
  name: '',
  total_cost: 0,
  volume: 0,
  unit: '',
  unit_cost: 0,
  stock_quantity: 0,
  stock_unit: '',
  stock_cost: 0,
  row: 0,
  components: [],
}) {
}

export class StockItem extends Record<{
  uuid: string | null;
  name: string;
  name_managed: string | null;
  brand_uuid: string | undefined,
  takeout_price: number;
  delivery_price: number;
  total_cost: number;
  takeout_cost_rate: number;
  delivery_cost_rate: number;
  tax_rate: number;
  row: number;
  components: StockComponent[];
}>({
  uuid: null,
  name: '',
  name_managed: null,
  brand_uuid: undefined,
  takeout_price: 0,
  delivery_price: 0,
  total_cost: 0,
  takeout_cost_rate: 0,
  delivery_cost_rate: 0,
  tax_rate: 0,
  row: 0,
  components: [],
}) {
}

export class BrandItem extends Record<{
  brand_uuid: string | null;
  brand_name: string;
  items: StockItem[];
}>({
  brand_uuid: null,
  brand_name: '',
  items: [new StockItem()],
}) {
}
