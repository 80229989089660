import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../store/store';
import { selectLocation } from '../../store/modules/locations';
import { postLinkItems, selectBrandItems, selectOptions, selectUnlinkItems } from '../../store/modules/stocks';
import { Autocomplete, Grid, List, ListItemButton, ListItemText, MenuItem, Paper, Select, SelectChangeEvent, Table, TableBody, TableCell, TableContainer, TableRow, TextField } from '@mui/material';
import { BrandItem, Location, StockItem } from '../../model/model';

const LinkItems = () => {
  const dispatch = useDispatch<AppDispatch>();
  const unlinkItems = useSelector(selectUnlinkItems)
  const brandItems: BrandItem[] = useSelector(selectBrandItems);
  const stockOptions: StockItem[] = useSelector(selectOptions);
  const location: Location = useSelector(selectLocation);

  const [selectionValue, setSelectionValue] = useState(null as string | null);
  const selections = () => {
    var options = [];
    var params:any = {};
    for (var i in brandItems) {
      for (var j in brandItems[i].items) {
        var name = (brandItems[i].items[j].name_managed ? brandItems[i].items[j].name_managed : brandItems[i].items[j].name) + '(' + brandItems[i].brand_name + ')';
        options.push(name);
        params[name] = brandItems[i].items[j].uuid;
      }
    }
    for (var i in stockOptions) {
      options.push(stockOptions[i].name);
      params[stockOptions[i].name] = stockOptions[i].uuid;
    }
    return {
      options: options,
      params: params,
    }
  }

  return (
    <TableContainer component={Paper} sx={{
      bgcolor: 'white',
      width: '80%',
      maxWidth: '1000px',
      height: '80%',
      overflowY: 'scroll',
    }}>
    <Table aria-label='simple table'>
        <TableBody>
            {unlinkItems
              .map((item:any, index: number) => (
                <TableRow
                  sx={{m: '10px auto', display: 'flex'}}
                  key={item.item_id}
                >
                  <TableCell sx={{
                    p:'0',
                    width: '100%',
                    borderRadius: '4px',
                    overflow: 'hidden',
                  }}>
                    <List component="div" disablePadding>
                      <Grid container>
                        <Grid item xs={6} sx={{
                          padding: '10px',
                        }}>
                          <ListItemText primary={item.item_name} />
                        </Grid>
                        <Grid item xs={6}>
                        <Autocomplete
                          sx={{width: '100%', mt: '10px'}}
                          id="link_item"
                          value={selectionValue}
                          options={selections().options}
                          renderInput={(params) => <TextField {...params} label="紐づける商品" />}
                          onChange={(event: any, newValue: string | null) => {
                            if(newValue) {
                              dispatch(postLinkItems({
                                order_item_id: item.item_id,
                                menu_item_uuid:selections().params[newValue],
                                location_uuid: location.uuid,
                              }));
                              setSelectionValue(null);
                            }
                          }}
                        />
                        </Grid>
                      </Grid>
                    </List>
                  </TableCell>
                </TableRow>
            ))}
        </TableBody>
    </Table>
    </TableContainer>
  );
};

export default LinkItems;