import Sidenav from '../components/Sidenav';
import { AppDispatch } from '../store/store';
import { Item, ItemSection, Location, Organization } from '../model/model';
import { getLocations, setLocation, selectLocations, selectLocationLoading, selectLocation, selectLocationError } from '../store/modules/locations';
import { selectOrganization } from '../store/modules/user';

import { useEffect, useRef, useState, forwardRef } from 'react';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress'
import { useSelector, useDispatch } from 'react-redux';
import { Alert, Box, Button, Collapse, List, ListItemButton, ListItemText, MenuItem, Select, SelectChangeEvent, Tab, TableCell, Tabs } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';

import { setItemSectionOpens, getSectionItems, selectItemLoading, selectItemSectionOpens, selectItemSections, selectOptiongroups, selectOptiongroupOpens, setOptiongroupOpens, postItemRow, setNewObject, getOptiongroups, getAllItems } from '../store/modules/items';


import MenuItems from '../components/Menu/MenuItems';
import MenuOptions from '../components/Menu/MenuOptions'

const Menu = () => {
  const locations: Location[] = useSelector(selectLocations);
  const location: Location = useSelector(selectLocation);
  const organization: Organization = useSelector(selectOrganization);
  const locationLoading: boolean = useSelector(selectLocationLoading);
  
  const [dragIndex, setDragIndex] = useState(0);

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      let token = localStorage.getItem('token');
      if (user && token) {
        if (organization) {
          dispatch(getLocations(organization.uuid));
        }
      } else {
        navigate('/signin');
      }
    });
  }, [organization]);

  useEffect(() => {
    if (location.uuid) {
      dispatch(getAllItems(organization.uuid));
      dispatch(getSectionItems(location.uuid));
      dispatch(getOptiongroups(location.uuid));
    }
  }, [location]);

  const handleLocationChange = (event: SelectChangeEvent) => {
    dispatch(setLocation(event.target.value as string));
  }
  
  const [tabValue, setTabValue] = useState('menu');
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  return (
    <>
      <Sidenav />
      <Grid container sx={{ mt:'0px', m:'0 auto', overflow: 'hidden'}}>
        {locationLoading ?
          <div style={{textAlign:'center'}}><CircularProgress /></div> :
        // locationError ?
        //   <div style={{textAlign:'center'}}><Alert severity="error">{locationError}</Alert></div> :
          <>
            <Grid item xs={6} sx={{height: '60px'}}>
              <Select
                sx={{width: '100%', maxWidth: '300px'}}
                labelId="location-select-label"
                id="location-select"
                value={location.uuid}
                label="Location"
                onChange={handleLocationChange}
              >
                {locations
                  .map((l: Location) => (
                    <MenuItem value={l.uuid} key={l.uuid}>{l.name}</MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12}>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                textColor="secondary"
                indicatorColor="secondary"
                aria-label="secondary tabs example"
              >
                <Tab value="menu" label="メニュー" />
                <Tab value="option" label="オプション" />
              </Tabs>
            </Grid>

            { tabValue == 'menu' ? <MenuItems /> : <MenuOptions /> }
          </>
        }
      </Grid>
    </>
  );
};

export default Menu;