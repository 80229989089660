import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Autocomplete, Box, Button, Checkbox, CircularProgress, Divider, FormControlLabel, FormGroup, Grid, MenuItem, Paper, Select, SelectChangeEvent, TextField } from '@mui/material';

import { Item, Option, Optiongroup, ItemSection } from '../../model/model';
import { AppDispatch } from '../../store/store';
import { connectItem, deleteItem, postItem, selectItemLoading, selectItemSections, selectAllItems, selectItemsLoading, selectOptionLoading, selectOptiongroups } from '../../store/modules/items';
import { selectOrganization } from '../../store/modules/user';
import { selectLocation } from '../../store/modules/locations';

export interface RefProps {
  children: Props;
  forwardRef?: React.Ref<HTMLDivElement>;
}

export interface Props {
  path: string;
  section: null | ItemSection
  item: null | Item
  optiongroup: null | Optiongroup
  option: null | Option
}

const MenuItemView = ({ children, forwardRef }: RefProps) => {
  const { path, section, item, optiongroup, option } = children;
  
  const organization = useSelector(selectOrganization);
  const location = useSelector(selectLocation);
  const items = useSelector(selectAllItems);
  const itemSections = useSelector(selectItemSections);
  const optiongroups = useSelector(selectOptiongroups);
  const itemLoading: boolean = useSelector(selectItemLoading);
  const optionLoading: boolean = useSelector(selectOptionLoading);
  const itemsLoading: boolean = useSelector(selectItemsLoading);

  // init form
  const [type, setType] = useState('');
  const [selectionValue, setSelectionValue] = useState(null as string | null);
  const selections = () => {
    var options = [];
    var params:any = {};
    for (var i in items) {
      if (section!.items.filter((item: Item) => item.uuid == items[i].uuid).length > 0) { continue; }
      options.push(items[i].name);
      params[items[i].name] = items[i].uuid;
    }
    return {
      options: options,
      params: params,
    }
  }

  const [values, setValues] = useState({
    name: path == 'section' ? section!.name : path == 'item' ? item!.name : path == 'optiongroup' ? optiongroup!.name : path == 'option' ? option!.name : '',
    takeoutPrice: path == 'item' ? item!.takeout_price : path == 'option' ? option!.takeout_price : null,
    deliveryPrice: path == 'item' ? item!.delivery_price : path == 'option' ? option!.delivery_price : null,
    taxRate: path == 'item' ? item!.tax_rate : path == 'option' ? option!.tax_rate : null,
    isRequired: path == 'optiongroup' ? optiongroup!.is_required : false,
    image: null as string | null,
    minSelection: path == 'optiongroup' ? optiongroup!.min_selection : null,
    maxSelection: path == 'optiongroup' ? optiongroup!.max_selection : null,
    addItems: [] as string[],
    removeItems: [] as string[],
    addOptiongroups: [] as string[],
    removeOptiongroups: [] as string[],
    displayType: path == 'item' ? item!.display_type : null,
  });
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.type == 'checkbox') {
      if (event.target.name == 'display-type') {
        if (event.target.checked) {
          setValues({
            ...values,
            displayType: 'main',
          })
        } else {
          setValues({
            ...values,
            displayType: 'sub',
          })
        }
      } else if (event.target.name == 'is-required') {
        setValues({
          ...values,
          isRequired: event.target.checked,
        });
      } else if (event.target.name == 'item') {
        var addItems = values.addItems;
        var removeItems = values.removeItems;
        
        if (event.target.checked) {
          if (!addItems.includes(event.target.id)) {
            addItems.push(event.target.id);
          }
          if (removeItems.includes(event.target.id)) {
            removeItems = removeItems.filter((uuid: string) => uuid != event.target.id);
          }
        } else {
          if (addItems.includes(event.target.id)) {
            addItems = addItems.filter((uuid: string) => uuid != event.target.id);
          }
          if (!removeItems.includes(event.target.id)) {
            removeItems.push(event.target.id);
          }
        }
        
        setValues({
          ...values,
          addItems: addItems,
          removeItems: removeItems,
        });
      } else if (event.target.name == 'optiongroup') {
        var addOptiongroups = values.addOptiongroups;
        var removeOptiongroups = values.removeOptiongroups;
        
        if (event.target.checked) {
          if (!addOptiongroups.includes(event.target.id)) {
            addOptiongroups.push(event.target.id);
          }
          if (removeOptiongroups.includes(event.target.id)) {
            removeItems = removeOptiongroups.filter((uuid: string) => uuid != event.target.id);
          }
        } else {
          if (addOptiongroups.includes(event.target.id)) {
            addItems = addOptiongroups.filter((uuid: string) => uuid != event.target.id);
          }
          if (!removeOptiongroups.includes(event.target.id)) {
            removeOptiongroups.push(event.target.id);
          }
        }
        
        setValues({
          ...values,
          addOptiongroups: addOptiongroups,
          removeOptiongroups: removeOptiongroups,
        });
      }
    } else {
      setValues({
        ...values,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleSelectChange = (event: SelectChangeEvent) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  }

  // handle submit
  const dispatch = useDispatch<AppDispatch>();
  const handleButton = () => {

    if (path == 'section') {
      dispatch(postItem({path: path, SectionProps: {
        uuid: section!.uuid,
        name: values.name,
        location_uuid: location.uuid,
        row: section!.row ? section!.row : itemSections.length + 1,
      }}))
    } else if (path == 'item') {
      dispatch(postItem({path: path, ItemInput: {
        itemProps: {
          uuid: item!.uuid,
          brand_uuid: item!.brand_uuid,
          name: values.name,
          takeout_price: Number(values.takeoutPrice),
          delivery_price: Number(values.deliveryPrice),
          tax_rate: Number(values.taxRate),
          section_uuid: section!.uuid,
          organization_uuid: organization.uuid,
          section_item_uuid: item!.section_item_uuid,
          row: item!.row ? item!.row : section!.items.length + 1,
          add_optiongroup_uuids: values.addOptiongroups,
          remove_optiongroup_uuids: values.removeOptiongroups,
          display_type: values.displayType ? values.displayType : section!.uuid ? 'sub' : null,
        },
        image: values.image,
      }}));
    } else if (path == 'optiongroup') {
      dispatch(postItem({path: path, OptiongroupProps: {
        uuid: optiongroup!.uuid,
        name: values.name,
        row: optiongroup!.row ? optiongroup!.row: null,
        location_uuid: location.uuid,
        is_required: values.isRequired,
        min_selection: values.minSelection ? Number(values.minSelection) : null,
        max_selection: values.maxSelection ? Number(values.maxSelection) : null,
        add_item_uuids: values.addItems,
        remove_item_uuids: values.removeItems,
      }}))
    } else if (path == 'option') {
      dispatch(postItem({path: path, OptionProps: {
        uuid: option!.uuid,
        name: values.name,
        takeout_price: Number(values.takeoutPrice),
        delivery_price: Number(values.deliveryPrice),
        tax_rate: Number(values.taxRate),
        optiongroup_uuid: optiongroup!.uuid,
        optiongroup_option_uuid: option!.optiongroup_option_uuid,
        unique_takeout_price: null,
        unique_delivery_price: null,
        row: optiongroup!.options.length + 1,
        organization_uuid: organization.uuid,
      }}))
    }
  }
  const handleDelete = () => {
    dispatch(deleteItem({
      type: path,
      uuid: path == 'section' ? section!.uuid! : path == 'item' ? item!.uuid! : path == 'optiongroup' ? optiongroup!.uuid! : path == 'option' ? option!.uuid! : '',
    }));
  }

  // handle image
  const [dragOver, setDragOver] = useState(false);
  const handleInputImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files) {
      return;
    }
    const fileArray = Array.from(files);
    fileArray.forEach((file) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const result = reader.result;
        if (typeof result !== "string") {
          return;
        }
        setValues({
          ...values,
          image: result,
        });
      };
      reader.readAsDataURL(file);
    });
  };
  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragOver(true);
  };
  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    setDragOver(false);
  };
  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const files = e.dataTransfer.files
    if (files.length === 0) {
      return
    }
    const file = files[0]
    if (file.type !== "image/png" && file.type !== "image/jpeg") {
      return
    }
    const reader = new FileReader()
    reader.onloadend = () => {
      const data = reader.result;
      if (typeof data !== "string") {
        return;
      }
      setValues({
        ...values,
        image: data,
      });
    };
    reader.readAsDataURL(file);
    setDragOver(false);
  };
  const handleImageClick = () => {
    const elem = document.getElementById("item-image");
    if (elem) {
      elem.click();
    }
  }

  return (
    <Paper className='FontM' sx={{
      display: 'flex',
      flexFlow: 'column',
      width: 'calc(100% - 140px)',
      maxWidth: '1000px',
      maxHeight: '80vh',
      background: 'white',
      // overflow: 'hidden',
      overflowY: 'auto',
      p: '10px',
    }}>
      {itemLoading ? <CircularProgress sx={{m:'auto'}} /> :
        <Grid container mb='10px'>
          {(path == 'item' && !values.name && type == '') ?
            <Grid item xs={12} sx={{m: '10px'}}>
              <Select
                sx={{width: '100%'}}
                id="type"
                name="type"
                label="選択"
                value={type}
                onChange={(event: SelectChangeEvent) => {
                  setType(event.target.value);
                }}
              >
                <MenuItem value='connect' key='connect'>既存のメニューを紐づけ</MenuItem>
                <MenuItem value='new' key='new'>新規メニューの作成</MenuItem>
              </Select>
            </Grid>
          : (path == 'item' && type == 'connect') ?
            <Autocomplete
              sx={{width: '100%', mt: '10px'}}
              id="connect"
              value={selectionValue}
              options={selections().options}
              renderInput={(params) => <TextField {...params} label="メニューを選択" />}
              onChange={(event: any, newValue: string | null) => {
                if(newValue) {
                  const itemUuid = selections().params[newValue];
                  dispatch(connectItem({
                    uuid: itemUuid,
                    section_uuid: section!.uuid!,
                    row: section!.items.length + 1,
                  }));
                }
              }}
            />
          : <>
            <Grid item xs={12} sx={{m: '10px'}}>
              <TextField sx={{width: '100%'}} id="name" name="name" label="Name" variant="filled"
                defaultValue={values.name}
                onChange={handleChange}/>
            </Grid>
            {(path == 'item' || path == 'option') ?
              <>
                <Grid item xs={5} sx={{p: '10px'}}>
                  <TextField sx={{width: '100%'}} id="takeout-price" name="takeoutPrice" label="Takeout Price" variant="filled" type="number"
                    defaultValue={values.takeoutPrice}
                    onChange={handleChange}/>
                </Grid>
                <Grid item xs={5} sx={{p: '10px'}}>
                  <TextField sx={{width: '100%'}} id="delivery-price" name="deliveryPrice" label="Delivery Price" variant="filled" type="number"
                    defaultValue={values.deliveryPrice}
                    onChange={handleChange}/>
                </Grid>
                <Grid item xs={2} sx={{p: '10px'}}>
                  <Select
                    sx={{width: '100%', maxWidth: '300px'}}
                    id="tax_rate"
                    name="taxRate"
                    label="消費税率"
                    value={String(values.taxRate)}
                    onChange={handleSelectChange}
                  >
                    <MenuItem value={0.08} key='0.08'>8%</MenuItem>
                    <MenuItem value={0.1} key='0.1'>10%</MenuItem>
                  </Select>
                </Grid>
              </> : <></>
            }
            {path == 'item' ?
              <>
                <Grid item xs={12}>
                  <Checkbox name='display-type'
                    defaultChecked={ item!.display_type == 'main' }
                    onChange={handleChange}/>メイン商品（メニュー画面で強調されます）
                </Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{display: 'flex', alignContent: 'center', alignItems: 'center', margin: 'auto', width: '100%', maxWidth: '320px', height: '240px', border: '2px dashed', borderRadius: '10px', overflow: 'hidden', 
                      backgroundColor: dragOver ? 'lightgray' : 'white',
                    }}
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onClick={handleImageClick}
                  >
                    {(values.image || item!.image_url) ? 
                      <img src={values.image ? values.image : item!.image_url!} style={{width: '100%', maxWidth: '320px', height: '240px', objectFit: 'contain'}} /> :
                      <div style={{margin: 'auto', fontSize: '16px'}}>画像をドラッグ＆ドロップ</div>
                    }
                  </Box>
                  <input
                    type='file'
                    id='item-image'
                    style={{display: 'none'}}
                    onChange={handleInputImage}
                  />
                </Grid>
                <Grid item xs={12} mt='5px' mb='5px'><Divider /></Grid>
                オプション
                <Grid item xs={12} sx={{display: 'flex', flexFlow: 'column', alignContent: 'center', overflowY: 'auto', maxHeight: '200px'}}>
                  { optionLoading ?  <CircularProgress sx={{m:'auto'}} /> :
                    <FormGroup>
                      {optiongroups.map((optiongroup: Optiongroup) => {
                        return <FormControlLabel key={optiongroup.uuid} name='optiongroup' label={optiongroup.name} control={
                            <Checkbox id={optiongroup.uuid!} defaultChecked={item!.optiongroup_uuids.includes(optiongroup.uuid!)} onChange={handleChange} />
                          } />
                      })}
                    </FormGroup>
                  }
                </Grid>
              </> : <></>
            }
            {(path == 'optiongroup') ?
            <>
              <Grid item xs={12} sx={{m: '10px'}}>
                必須選択<Checkbox name='is-required'
                  defaultChecked={ optiongroup!.is_required }
                  onChange={handleChange}/>
                <TextField sx={{width: '100px', ml: '20px'}} id="min-selection" name="minSelection" label="min" variant="filled" type="number"
                  defaultValue={ optiongroup!.min_selection }
                  onChange={handleChange}/>
                <TextField sx={{width: '100px', ml: '20px'}} id="max-selection" name="maxSelection" label="max" variant="filled" type="number"
                  defaultValue={ optiongroup!.max_selection }
                  onChange={handleChange}/>
              </Grid>
              商品に追加
              <Grid item xs={12} sx={{display: 'flex', flexFlow: 'column', alignContent: 'center', overflowY: 'auto', maxHeight: '200px'}}>
              { itemsLoading ?  <CircularProgress sx={{m:'auto'}} /> :
                  <FormGroup>
                      {items.map((item: Item) => {
                        return <FormControlLabel key={item.uuid} name='item' label={item.name} control={
                            <Checkbox id={item.uuid!} defaultChecked={optiongroup!.item_uuids.includes(item.uuid!)} onChange={handleChange} />
                          } />
                      })}
                  </FormGroup>
              }
              </Grid>
            </> : <></>
            }
            <Grid item xs={12} sx={{textAlign: 'center', mt: '10px'}}>
              <Button sx={{m: '10px'}} variant="contained" onClick={handleButton}>Apply</Button>
              <Button sx={{m: '10px'}} color="error" variant="contained" onClick={handleDelete}>Delete</Button>
            </Grid>
          </>
        }
        </Grid>
      }
    </Paper>
  );
};

export default MenuItemView;