import StockView, { Props } from './StockView'
import { AppDispatch } from '../../store/store';
import { BrandItem, Organization, StockIngredient, StockItem, StockPreparation } from '../../model/model';

import { useEffect, useRef, useState, forwardRef } from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Drawer from '@mui/material/Drawer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button, CircularProgress, Collapse, List, ListItemButton, ListItemText, MenuItem, Select, SelectChangeEvent, Tab, TableCell, Tabs } from '@mui/material';

import DragHandleOutlinedIcon from '@mui/icons-material/DragHandleOutlined';
import Draggable from 'react-draggable';
import { getIngredients, getItems, getPreparations, postStockRow, selectBrandItems, selectIngredients, selectPreparations, selectStockLoadings, setNewObject } from '../../store/modules/stocks';
import { AddOutlined } from '@mui/icons-material';
import { selectOrganization } from '../../store/modules/user';


const StockPreparations = () => {
  const preparations: StockPreparation[] = useSelector(selectPreparations);
  const [dragIndex, setDragIndex] = useState(0);

  const dispatch = useDispatch<AppDispatch>();
  
  const organization: Organization = useSelector(selectOrganization);
  const ingredients: StockIngredient[] = useSelector(selectIngredients);
  const stockLoadings = useSelector(selectStockLoadings);
  useEffect(() => {
    if (organization) {
      if (preparations.length == 0) {
        dispatch(getPreparations(organization.uuid));
      }
      if (ingredients.length == 0) {
        dispatch(getIngredients(organization.uuid));
      }
    }
  }, [organization]);

  useEffect(() => {
    if (!stockLoadings.post && showModal) {
      setShowModal(false);
      dispatch(getPreparations(organization.uuid));
    }
  }, [stockLoadings.post]);


  const [showModal, setShowModal] = useState(false);
  const [path, setPath] = useState('');
  const [item, setItem] = useState(new StockItem());
  const [option, setOption] = useState(new StockItem());
  const [preparation, setPreparation] = useState(new StockPreparation());
  const [ingredient, setIngredient] = useState(new StockIngredient());


  const displayItems = (preparation: StockPreparation, prepIndex: number) => (
    <>
      <TableRow
        sx={{display: 'flex'}}
        key={preparation.uuid}
      >
        <TableCell sx={{
          p:'0',
          width: '100%',
        }}>
          <Draggable
            handle=".handle"
            axis="y"
            defaultPosition={{x: 0, y: 0}}
            position={{x:0, y: 0}}
            bounds={{top: -prepIndex*50, bottom: (preparations.length-prepIndex-1)*50}}
            scale={1}
            onDrag={(e, position) => {
              const {x, y} = position;
              console.log(y)
              const newIndex = (y < 0 ? -1 : 1) * Math.round((Math.abs(y) - 10) / 50);
              if (dragIndex != newIndex) {
                setDragIndex(newIndex);
              }
            }}
            onStop={() => {
              if (dragIndex != 0) {
                const newRow = preparation.row + dragIndex;
                var param = [];
                var items = [];
                for (var prep of preparations) {
                  if (prep.row == preparation.row) {
                    items.push({...prep, row: newRow});
                    param.push({type: 'preparation', uuid: prep.uuid!, row: newRow});
                  } else if (prep.row > preparation.row && prep.row <= newRow) {
                    items.push({...prep, row: prep.row - 1});
                    param.push({type: 'preparation', uuid: prep.uuid!, row:prep.row - 1});
                  } else if (prep.row < preparation.row && prep.row >= newRow) {
                    items.push({...prep, row: prep.row + 1});
                    param.push({type: 'preparation', uuid: prep.uuid!, row:prep.row + 1});
                  } else {
                    items.push(prep);
                  }
                }
                items.sort((i, j) => i.row - j.row);
                dispatch(setNewObject({preparations: items}));
                dispatch(postStockRow(param));
              }
            }}
          >
            <List component="div" disablePadding>
              <Grid container>
                <Grid item className="handle" xs='auto' sx={{height: '50px', width:'50px !important', textAlign: 'center'}}>
                  <DragHandleOutlinedIcon sx={{height: '50px'}} />
                </Grid>
                <Grid item xs>
                  <ListItemButton sx={{ p:0, pr:'20px', height: '50px' }} onClick={() => {
                    setPath('preparation');
                    setPreparation(preparation);
                    setShowModal(true);
                  }}>
                    <Grid item xs={10}>
                      <ListItemText primary={preparation.name} />
                    </Grid>
                    <Grid item xs={2} sx={{textAlign: 'right'}}>
                      <ListItemText primary={'¥ ' + Math.round(preparation.total_cost).toLocaleString()} />
                    </Grid>
                  </ListItemButton>
                </Grid>
              </Grid>
            </List>
          </Draggable>
        </TableCell>
      </TableRow>
    </>
  )

  const Ref = forwardRef<HTMLDivElement, Props>(
    ({ path, item, option, brandUuid, preparation, ingredient, category }, ref) => {
      return <StockView
        children={{
          path: path,
          item: item,
          option: option,
          brandUuid: brandUuid,
          preparation: preparation,
          ingredient: ingredient,
          category: category,
        }}
        forwardRef={ref}
      />;
    }
  );

  return (
    <>
      {stockLoadings.preparations ?
        <div style={{textAlign:'center'}}><CircularProgress /></div> :
        <>              
          <TableContainer component={Paper} sx={{
            bgcolor: 'transparent',
            width: '100%',
            maxWidth: '1000px',
          }}>
            <Table aria-label='simple table'>
              <TableBody>
              {preparations
                    .map((preparation:StockPreparation, prepIndex: number) => (
                    displayItems(preparation, prepIndex)
                ))}

                  <TableRow
                    sx={{m: '10px auto', display: 'flex'}}
                    key='new'
                  >
                    <TableCell sx={{
                      p:'0',
                      width: '100%',
                      background: '#E84566',
                      borderRadius: '4px',
                      overflow: 'hidden',
                    }}>
                      <ListItemButton sx={{
                          background: '#F29C97',
                        }} onClick={() => {
                          setPath('preparation');
                          setPreparation(new StockPreparation({components: []}));
                          setShowModal(true);
                        }}>
                      <AddOutlined sx={{ m: 'auto' }} />
                    </ListItemButton>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Modal
            open={showModal}
            onClose={() => setShowModal(false)}
            sx={{
              justifyContent: 'center',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Ref 
              path={path}
              item={item}
              option={option}
              brandUuid={''}
              preparation={preparation}
              ingredient={ingredient}
              category={null}
            />
          </Modal>
        </>
      }
    </>
  )
};

export default StockPreparations;