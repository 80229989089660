
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { AppDispatch } from "../store/store";
import { getLocations, postCode, postSection, selectLocation, selectLocations } from "../store/modules/locations";
import { Location, FreeeSection, Organization, Brand, FreeeItem } from '../model/model';
import { Box, Button, Grid, ListItemText, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { auth } from '../firebase';
import { onAuthStateChanged } from "firebase/auth";
import { connectFreee, disconnectFreee, getFreeeSectionItems, getOrganization, selectItems, selectOrganization, selectSections } from "../store/modules/user";
import { connectBrandFreee, getAllBrands, selectAllBrands } from "../store/modules/brands";

const Freee = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    const search = useLocation().search;
    const query = new URLSearchParams(search);
    const code = query.get('code');

    const freeeSections: null | FreeeSection[] = useSelector(selectSections);
    const freeeItems:  null | FreeeSection[] = useSelector(selectItems);

    const organization: Organization = useSelector(selectOrganization);
    const locations: Location[] = useSelector(selectLocations);
    const brands: Brand[] = useSelector(selectAllBrands);

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
          let token = localStorage.getItem('token');
          if (user && token) {
            if (code) {
                if (organization) {
                    if (!organization.freee_active) {
                        dispatch(connectFreee({code: code, organization_uuid: organization.uuid}));
                    } else if (!freeeSections) {
                        dispatch(getFreeeSectionItems(organization.uuid));
                    }
                    if (!locations.length) {
                        dispatch(getLocations(organization.uuid));
                    }
                    if (!brands.length) {
                        dispatch(getAllBrands(organization.uuid));
                    }
                } else {
                    dispatch(getOrganization());
                }
            }
          } else {
            navigate('/signin');
          }
        });
    }, [organization]);

    return (
        <Box sx={{width:'100%', textAlign: 'center'}}>
            <Grid container>
                <Grid item xs={12} sx={{ padding: '10px' }}>部門を選択する</Grid>
                { locations && freeeSections ?
                    locations.map((loc: Location) => (<>
                        <Grid item xs={6} sx={{ padding: '5px' }}>
                            <ListItemText primary={loc.name} />
                        </Grid>
                        <Grid item xs={6} sx={{ padding: '5px' }}>
                            <Select
                                sx={{width: '100%'}}
                                id="link_item"
                                name="linkItem"
                                label="紐づける商品"
                                defaultValue={loc.freee_section_id ? loc.freee_section_id : ''}
                                onChange={(event: SelectChangeEvent) => {
                                    dispatch(postSection({
                                        section_id: event.target.value as string,
                                        location_uuid: loc.uuid
                                    }))
                                }}
                            >
                                {freeeSections.map((section: FreeeSection) => 
                                    <MenuItem value={section.id} key={section.id}>{section.name}</MenuItem>
                                )}
                            </Select>
                        </Grid>
                    </>))
                : null}
                <Grid item xs={12} sx={{ padding: '10px' }}>品目を選択する</Grid>
                { brands && freeeItems ?
                    brands.map((brand: Brand) => (<>
                        <Grid item xs={6} sx={{ padding: '5px' }}>
                            <ListItemText primary={brand.name} />
                        </Grid>
                        <Grid item xs={6} sx={{ padding: '5px' }}>
                            <Select
                                sx={{width: '100%'}}
                                id="link_item"
                                name="linkItem"
                                label="紐づける商品"
                                defaultValue={brand.freee_item_id ? brand.freee_item_id : ''}
                                onChange={(event: SelectChangeEvent) => {
                                    dispatch(connectBrandFreee({
                                        brand_uuid: brand.uuid,
                                        item_id: event.target.value as string,
                                    }))
                                }}
                            >
                                {freeeItems.map((item: FreeeItem) => 
                                    <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>
                                )}
                            </Select>
                        </Grid>
                    </>))
                : null}
            </Grid>  
            <Button sx={{m: '10px'}} variant="contained" onClick={() => navigate('/credentials')}>Done</Button>
            <Button sx={{m: '10px'}} color="error" variant="contained" onClick={() => dispatch(disconnectFreee())}>Disconnect</Button>
        </Box>
    )
}

export default Freee;
