import React, { useState } from 'react';

import { Dayjs } from 'dayjs';
import { LocalizationProvider, DateRangeCalendar, DateRange } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { selectDateRange, setDateRange } from '../store/modules/stocks';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../store/store';

const DateSelector = () => {
  const dateRange = useSelector(selectDateRange);
	const [dateSelect, setDateSelect] = useState([dateRange[0], null] as DateRange<Dayjs>);

  const dispatch = useDispatch<AppDispatch>();
	const selectDate = (newRange: DateRange<Dayjs>) => {
		setDateSelect(newRange);
		if (newRange[1]) {
      dispatch(setDateRange(newRange));
		}
	}
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateRangeCalendar
            sx={{backgroundColor: 'white'}}
            value={dateSelect}
            onChange={selectDate}
            // inputFormat='YYYY/MM/DD'
            // mask='____/__/__'
            // renderInput={({inputRef, inputProps, InputProps}) => (
            //   <div className='Date-input'>
            //     <input ref={inputRef} {...inputProps} />
            //     {InputProps?.endAdornment}
            //   </div>
            // )}
            />
    </LocalizationProvider>
  );
};

export default DateSelector;