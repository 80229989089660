import StockView, { Props } from './StockView'
import { AppDispatch } from '../../store/store';
import { BrandItem, Organization, StockIngredient, StockItem, StockPreparation } from '../../model/model';

import { useEffect, useRef, useState, forwardRef } from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Drawer from '@mui/material/Drawer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button, CircularProgress, Collapse, List, ListItemButton, ListItemText, MenuItem, Select, SelectChangeEvent, Tab, TableCell, Tabs } from '@mui/material';


import DragHandleOutlinedIcon from '@mui/icons-material/DragHandleOutlined';
import Draggable from 'react-draggable';
import { getIngredients, getItems, getPreparations, postStockRow, selectBrandItems, selectIngredients, selectPreparations, selectStockLoadings, setNewObject, setBrandUuid, selectBrandUuid } from '../../store/modules/stocks';
import { AddOutlined } from '@mui/icons-material';
import { selectOrganization } from '../../store/modules/user';
import { getAllBrands } from '../../store/modules/brands';


const StockItems = () => {
  const brandItems: BrandItem[] = useSelector(selectBrandItems);
  const [dragIndex, setDragIndex] = useState(0);

  const dispatch = useDispatch<AppDispatch>();
  
  const organization: Organization = useSelector(selectOrganization);
  const preparations: StockPreparation[] = useSelector(selectPreparations);
  const ingredients: StockIngredient[] = useSelector(selectIngredients);
  const stockLoadings = useSelector(selectStockLoadings);
  useEffect(() => {
    if (organization) {
      dispatch(getAllBrands(organization.uuid))
      if (brandItems.length == 0) {
        dispatch(getItems(organization.uuid));
      }
      if (preparations.length == 0) {
        dispatch(getPreparations(organization.uuid));
      }
      if (ingredients.length == 0) {
        dispatch(getIngredients(organization.uuid));
      }
    }
  }, [organization]);

  useEffect(() => {
    if (!stockLoadings.post && showModal) {
      setShowModal(false);
      dispatch(getItems(organization.uuid));
    }
  }, [stockLoadings.post]);

  const brandUuid: string = useSelector(selectBrandUuid);
  useEffect(() => {
    var localUuid = localStorage.getItem('brand_uuid');
    if (localUuid) {
      if (brandItems.filter((b:BrandItem) => b.brand_uuid == localUuid).length > 0) {
        dispatch(setBrandUuid(localUuid!))
      }
      return;
    }
    if (brandItems.length > 0) {
      dispatch(setBrandUuid(brandItems[0].brand_uuid!))
    }
  }, [brandItems]);

  const [showModal, setShowModal] = useState(false);
  const [path, setPath] = useState('');
  const [item, setItem] = useState(new StockItem());
  const [option, setOption] = useState(new StockItem());
  const [preparation, setPreparation] = useState(new StockPreparation());
  const [ingredient, setIngredient] = useState(new StockIngredient());

  const handleBrandChange = (event: SelectChangeEvent) => {
    console.log(event.target.value)
    localStorage.setItem('brand_uuid', event.target.value as string);
    dispatch(setBrandUuid(event.target.value as string))
  }

  const displayItems = (item: StockItem, index: number) => (
        <TableRow
          sx={{display: 'flex'}}
          key={item.uuid}
        >
          <TableCell sx={{
            p:'0',
            width: '100%',
          }}>
            <Draggable
              handle=".handle"
              axis="y"
              defaultPosition={{x: 0, y: 0}}
              position={{x:0, y: 0}}
              bounds={{top: -index*50, bottom: (brandItems.filter((b:BrandItem) => b.brand_uuid == brandUuid)[0].items.length-index-1)*50}}
              scale={1}
              onDrag={(e, position) => {
                const {x, y} = position;
                const newIndex = Math.round(y / 50);
                if (dragIndex != newIndex) {
                  setDragIndex(newIndex);
                }
              }}
              onStop={() => {
                if (dragIndex != 0) {
                  const newRow = item.row + dragIndex;
                  var param = [];
                  var newItems = [];
                  for (var it of brandItems.filter((b:BrandItem) => b.brand_uuid == brandUuid)[0].items) {
                    if (it.row == item.row) {
                      newItems.push({...it, row: newRow});
                      param.push({type: 'item', uuid: it.uuid!, row: newRow});
                    } else if (it.row > item.row && it.row <= newRow) {
                      newItems.push({...it, row: it.row - 1});
                      param.push({type: 'item', uuid: it.uuid!, row:it.row - 1});
                    } else if (it.row < item.row && it.row >= newRow) {
                      newItems.push({...it, row: it.row + 1});
                      param.push({type: 'item', uuid: it.uuid!, row:it.row + 1});
                    } else {
                      newItems.push(it);
                    }
                  }
                  newItems.sort((i, j) => i.row - j.row);
                  dispatch(setNewObject({items: newItems}));
                  dispatch(postStockRow(param));
                }
              }}
            >
              <List component="div" disablePadding>
                <Grid container>
                  <Grid item className="handle" xs='auto' sx={{height: '50px', width:'50px !important', textAlign: 'center'}}>
                    <DragHandleOutlinedIcon sx={{height: '50px'}} />
                  </Grid>
                  <Grid item xs>
                    <ListItemButton sx={{ p:0, pr:'20px', height: '50px' }} onClick={() => {
                      setPath('item');
                      setItem(item);
                      setShowModal(true);
                    }}>
                      <Grid item xs={8}>
                        <ListItemText primary={item.name} />
                      </Grid>
                      <Grid item xs={2} sx={{textAlign: 'right'}}>
                        <ListItemText primary={'¥'+item.delivery_price.toLocaleString()} />
                      </Grid>
                      <Grid item xs={2} sx={{textAlign: 'right'}}>
                        <ListItemText primary={Math.round(item.delivery_cost_rate * 1000)/10 + '%'} />
                      </Grid>
                    </ListItemButton>
                  </Grid>
                </Grid>
              </List>
            </Draggable>
          </TableCell>
        </TableRow>
  )

  const Ref = forwardRef<HTMLDivElement, Props>(
    ({ path, item, option, brandUuid, preparation, ingredient, category }, ref) => {
      return <StockView
        children={{
          path: path,
          item: item,
          option: option,
          brandUuid: brandUuid,
          preparation: preparation,
          ingredient: ingredient,
          category: category,
        }}
        forwardRef={ref}
      />;
    }
  );

  return (
    <>
      {stockLoadings.items ?
        <div style={{textAlign:'center'}}><CircularProgress /></div> :
        <>
          <Grid item xs={12} sx={{height: '60px'}}>
            <Select
              sx={{width: '100%', maxWidth: '300px'}}
              labelId="brand-select-label"
              id="brand-select"
              value={brandUuid}
              label="Brand"
              onChange={handleBrandChange}
            >
              {brandItems
                .map((b: BrandItem) => (
                  <MenuItem value={b.brand_uuid!} key={b.brand_uuid!}>{b.brand_name}</MenuItem>
              ))}
            </Select>
          </Grid>
              
          <TableContainer component={Paper} sx={{
            bgcolor: 'transparent',
            width: '100%',
            maxWidth: '1000px',
          }}>
            <Table aria-label='simple table'>
              <TableBody>
                {brandItems.filter((b:BrandItem) => b.brand_uuid == brandUuid).length > 0 ? brandItems.filter((b:BrandItem) => b.brand_uuid == brandUuid)[0].items
                  .map((item: StockItem, index: number) => (
                    displayItems(item, index)
                  )): <></> } 
                  <TableRow
                    sx={{m: '10px auto', display: 'flex'}}
                    key='new'
                  >
                    <TableCell sx={{
                      p:'0',
                      width: '100%',
                      background: '#E84566',
                      borderRadius: '4px',
                      overflow: 'hidden',
                    }}>
                      <ListItemButton sx={{
                          background: '#F29C97',
                        }} onClick={() => {
                          setPath('item');
                          setItem(new StockItem({brand_uuid: brandUuid, tax_rate: 0.08, row: brandItems.filter((b:BrandItem) => b.brand_uuid == brandUuid)[0].items.length + 1}));
                          setShowModal(true);
                        }}>
                        <AddOutlined sx={{ m: 'auto' }} />
                      </ListItemButton>
                    </TableCell>
                  </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Modal
            open={showModal}
            onClose={() => setShowModal(false)}
            sx={{
              justifyContent: 'center',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Ref 
              path={path}
              item={item}
              option={option}
              brandUuid={brandUuid}
              preparation={preparation}
              ingredient={ingredient}
              category={null}
            />
          </Modal>
        </>
      }
    </>
  )
};

export default StockItems;