import Sidenav from '../components/Sidenav';
import { AppDispatch } from '../store/store';
import { selectUserLoading } from '../store/modules/user';

import { useEffect, useRef, useState, forwardRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CircularProgress, Grid, Tab, Tabs } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';

import StockItems from '../components/Stock/StockItems';
import StockOptions from '../components/Stock/StockOptions';
import StockSummary from '../components/Stock/StockSummary';
import StockPreparations from '../components/Stock/StockPreparations';
import StockIngredients from '../components/Stock/StockIngredients';

const Stock = () => {
  const loading: boolean = useSelector(selectUserLoading);

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      let token = localStorage.getItem('token');
      if (!user || !token) {
        navigate('/signin');
      }
    });
  }, []);

  const [tabValue, setTabValue] = useState(localStorage.getItem('tab_value') ? localStorage.getItem('tab_value') : 'summary');
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
    localStorage.setItem('tab_value', newValue);
  };

  return (
    <>
      <Sidenav />
      <Grid container sx={{ pt:'40px', m:'0 auto', overflow: 'hidden', alignContent: 'baseline'}}>
        {/* tabs */}
        <Grid item xs={12}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
          >
            <Tab value="summary" label="サマリー" />
            <Tab value="items" label="商品" />
            <Tab value="options" label="オプション" />
            <Tab value="preparations" label="仕込み" />
            <Tab value="ingredients" label="材料" />
          </Tabs>
        </Grid>
        {/* contents */}
        <Grid item xs={12} sx={{
          justifyContent: 'center',
          height: 'calc(100vh - 100px)',
          pt: '20px',
          pb: '40px',
          overflowY: 'auto',
        }}>
        {
          loading ?
            <div style={{textAlign:'center'}}><CircularProgress /></div> :
          
            tabValue == 'summary' ? <StockSummary></StockSummary> :
            tabValue == 'items' ? <StockItems></StockItems> :
            tabValue == 'options' ? <StockOptions></StockOptions> :
            tabValue == 'preparations' ? <StockPreparations></StockPreparations> :
            tabValue == 'ingredients' ? <StockIngredients></StockIngredients> :
            <></>
        }
        </Grid>
      </Grid>
    </>
  );
};

export default Stock;