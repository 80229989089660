import StockView, { Props } from './StockView'
import { AppDispatch } from '../../store/store';
import { BrandItem, Organization, StockIngredient, StockItem, StockPreparation } from '../../model/model';

import { useEffect, useRef, useState, forwardRef } from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Drawer from '@mui/material/Drawer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button, CircularProgress, Collapse, List, ListItemButton, ListItemText, MenuItem, Select, SelectChangeEvent, Tab, TableCell, Tabs } from '@mui/material';


import DragHandleOutlinedIcon from '@mui/icons-material/DragHandleOutlined';
import Draggable from 'react-draggable';
import { getIngredients, getItems, getPreparations, postStockRow, selectBrandItems, selectIngredients, selectPreparations, selectStockLoadings, setNewObject, setBrandUuid, selectBrandUuid, selectOptions, getOptions } from '../../store/modules/stocks';
import { AddOutlined } from '@mui/icons-material';
import { selectOrganization } from '../../store/modules/user';


const StockOptions = () => {
  const options: StockItem[] = useSelector(selectOptions);
  const [dragIndex, setDragIndex] = useState(0);

  const dispatch = useDispatch<AppDispatch>();
  
  const organization: Organization = useSelector(selectOrganization);
  const preparations: StockPreparation[] = useSelector(selectPreparations);
  const ingredients: StockIngredient[] = useSelector(selectIngredients);
  const stockLoadings = useSelector(selectStockLoadings);
  useEffect(() => {
    if (organization) {
      if (options.length == 0) {
        dispatch(getOptions(organization.uuid));
      }
      if (preparations.length == 0) {
        dispatch(getPreparations(organization.uuid));
      }
      if (ingredients.length == 0) {
        dispatch(getIngredients(organization.uuid));
      }
    }
  }, [organization]);

  useEffect(() => {
    if (!stockLoadings.post && showModal) {
      setShowModal(false);
      dispatch(getOptions(organization.uuid));
    }
  }, [stockLoadings.post]);

  const [showModal, setShowModal] = useState(false);
  const [path, setPath] = useState('');
  const [item, setItem] = useState(new StockItem());
  const [option, setOption] = useState(new StockItem());
  const [preparation, setPreparation] = useState(new StockPreparation());
  const [ingredient, setIngredient] = useState(new StockIngredient());

  const displayItems = (option: StockItem, index: number) => (
        <TableRow
          sx={{display: 'flex'}}
          key={option.uuid}
        >
          <TableCell sx={{
            p:'0',
            width: '100%',
          }}>
            <Draggable
              handle=".handle"
              axis="y"
              defaultPosition={{x: 0, y: 0}}
              position={{x:0, y: 0}}
              bounds={{top: -index*50, bottom: (options.length-index-1)*50}}
              scale={1}
              onDrag={(e, position) => {
                const {x, y} = position;
                const newIndex = Math.round(y / 50);
                if (dragIndex != newIndex) {
                  setDragIndex(newIndex);
                }
              }}
              onStop={() => {
                if (dragIndex != 0) {
                  const newRow = option.row + dragIndex;
                  var param = [];
                  var items = [];
                  for (var op of options) {
                    if (op.row == option.row) {
                      items.push({...op, row: newRow});
                      param.push({type: 'option', uuid: op.uuid!, row: newRow});
                    } else if (op.row > option.row && op.row <= newRow) {
                      items.push({...op, row: op.row - 1});
                      param.push({type: 'option', uuid: op.uuid!, row:op.row - 1});
                    } else if (op.row < option.row && op.row >= newRow) {
                      items.push({...op, row: op.row + 1});
                      param.push({type: 'option', uuid: op.uuid!, row:op.row + 1});
                    } else {
                      items.push(op);
                    }
                  }
                  items.sort((i, j) => i.row - j.row);
                  dispatch(setNewObject({options: options}));
                  dispatch(postStockRow(param));
                }
              }}
            >
              <List component="div" disablePadding>
                <Grid container>
                  <Grid item className="handle" xs='auto' sx={{height: '50px', width:'50px !important', textAlign: 'center'}}>
                    <DragHandleOutlinedIcon sx={{height: '50px'}} />
                  </Grid>
                  <Grid item xs>
                    <ListItemButton sx={{ p:0, pr:'20px', height: '50px' }} onClick={() => {
                      setPath('option');
                      setOption(option);
                      setShowModal(true);
                    }}>
                      <Grid item xs={8}>
                        <ListItemText primary={option.name} />
                      </Grid>
                      <Grid item xs={2} sx={{textAlign: 'right'}}>
                        <ListItemText primary={'¥'+option.delivery_price.toLocaleString()} />
                      </Grid>
                      <Grid item xs={2} sx={{textAlign: 'right'}}>
                        <ListItemText primary={Math.round(option.delivery_cost_rate * 1000)/10 + '%'} />
                      </Grid>
                    </ListItemButton>
                  </Grid>
                </Grid>
              </List>
            </Draggable>
          </TableCell>
        </TableRow>
  )

  const Ref = forwardRef<HTMLDivElement, Props>(
    ({ path, item, option, brandUuid, preparation, ingredient, category }, ref) => {
      return <StockView
        children={{
          path: path,
          item: item,
          option: option,
          brandUuid: brandUuid,
          preparation: preparation,
          ingredient: ingredient,
          category: category,
        }}
        forwardRef={ref}
      />;
    }
  );

  return (
    <>
      {stockLoadings.items ?
        <div style={{textAlign:'center'}}><CircularProgress /></div> :
        <>
          <TableContainer component={Paper} sx={{
            bgcolor: 'transparent',
            width: '100%',
            maxWidth: '1000px',
          }}>
            <Table aria-label='simple table'>
              <TableBody>
                {options
                  .map((item: StockItem, index: number) => (
                    displayItems(item, index)
                  ))
                } 
                <TableRow
                  sx={{m: '10px auto', display: 'flex'}}
                  key='new'
                >
                  <TableCell sx={{
                    p:'0',
                    width: '100%',
                    background: '#E84566',
                    borderRadius: '4px',
                    overflow: 'hidden',
                  }}>
                    <ListItemButton sx={{
                        background: '#F29C97',
                      }} onClick={() => {
                        setPath('option');
                        setOption(new StockItem({tax_rate: 0.08, row: options.length + 1}));
                        setShowModal(true);
                      }}>
                      <AddOutlined sx={{ m: 'auto' }} />
                    </ListItemButton>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Modal
            open={showModal}
            onClose={() => setShowModal(false)}
            sx={{
              justifyContent: 'center',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Ref 
              path={path}
              item={item}
              option={option}
              brandUuid={''}
              preparation={preparation}
              ingredient={ingredient}
              category={null}
            />
          </Modal>
        </>
      }
    </>
  )
};

export default StockOptions;