import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import { FreeeItem, FreeeSection, Organization } from '../../model/model';

const initialState = {
  user: {
    uid: '',
    name: '',
    email: '',
    type: '',
  },
  organization: null as null | Organization,
  freeeSections: null as null | FreeeSection[],
  freeeItems: null as null | FreeeItem[],
  loading: true,
  error: false,
}

export const getUser = createAsyncThunk('user/getUser', async () => {
  const res = await axios.get('/users');
  return res.data;
});

export const getOrganization = createAsyncThunk('user/getOrganization', async () => {
  const res = await axios.get('/organizations');
  return res.data;
});

type ConnectProps = {
  code: string;
  organization_uuid: string;
}

export const getFreeeSectionItems = createAsyncThunk('user/getFreeeSectionItems', async (uuid: string) => {
  const res = await axios.get('/organizations/freee/section-items?organization_uuid='+uuid);
  return res.data;
});

export const connectFreee = createAsyncThunk('user/connectFreee', async (props: ConnectProps) => {
  const res = await axios.post('/organizations/freee/connect', props);
  return res.data;
});

export const disconnectFreee = createAsyncThunk('user/disconnectFreee', async () => {
  const res = await axios.post('/organizations/freee/disconnect');
  return res.data;
});

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
      resetUser: (state) => {
        state.user = initialState.user;
      },
    },
    extraReducers: (builder) => {
      builder.addCase(getUser.pending, (state, action) => {
        state.loading = true;
      });
      builder.addCase(getUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.data;
      });
      builder.addCase(getUser.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      });
      builder.addCase(getOrganization.pending, (state, action) => {
        state.loading = true;
      });
      builder.addCase(getOrganization.fulfilled, (state, action) => {
        state.loading = false;
        state.organization = action.payload.data;
        console.log(action.payload.data)
      });
      builder.addCase(getOrganization.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      });
      builder.addCase(getFreeeSectionItems.pending, (state, action) => {
        state.loading = true;
      });
      builder.addCase(getFreeeSectionItems.fulfilled, (state, action) => {
        state.loading = false;
        state.freeeSections = action.payload.data.sections;
        state.freeeItems = action.payload.data.items;
      });
      builder.addCase(getFreeeSectionItems.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      });
      builder.addCase(connectFreee.pending, (state, action) => {
        state.loading = true;
      });
      builder.addCase(connectFreee.fulfilled, (state, action) => {
        state.loading = false;
        state.organization = action.payload.data.organization;
        state.freeeSections = action.payload.data.sections;
      });
      builder.addCase(connectFreee.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      });
      builder.addCase(disconnectFreee.pending, (state, action) => {
        state.loading = true;
      });
      builder.addCase(disconnectFreee.fulfilled, (state, action) => {
        state.loading = false;
        state.organization!.freee_active = false;
      });
      builder.addCase(disconnectFreee.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      });
    },
})

export const { resetUser } = userSlice.actions;

export const selectUser = (state: any) => {return state.user.user};
export const selectOrganization = (state: any) => {return state.user.organization};
export const selectUserLoading = (state: any) => {return state.user.loadnig};
export const selectSections = (state: any) => {return state.user.freeeSections};
export const selectItems = (state: any) => {return state.user.freeeItems};

export default userSlice.reducer;