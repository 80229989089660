import StockView, { Props } from './StockView'
import { AppDispatch } from '../../store/store';
import { BrandItem, Organization, StockCategory, StockIngredient, StockItem, StockPreparation } from '../../model/model';

import { useEffect, useRef, useState, forwardRef } from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Drawer from '@mui/material/Drawer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button, CircularProgress, Collapse, List, ListItemButton, ListItemText, MenuItem, Select, SelectChangeEvent, Tab, TableCell, Tabs } from '@mui/material';

import DragHandleOutlinedIcon from '@mui/icons-material/DragHandleOutlined';
import Draggable from 'react-draggable';
import { getIngredients, getItems, getPreparations, postStockRow, selectBrandItems, selectCategories, selectIngredients, selectPreparations, selectStockLoadings, setNewObject } from '../../store/modules/stocks';
import { AddOutlined } from '@mui/icons-material';
import { selectOrganization } from '../../store/modules/user';


const StockIngredients = () => {
  const preparations: StockPreparation[] = useSelector(selectPreparations);
  const [dragIndex, setDragIndex] = useState(0);

  const dispatch = useDispatch<AppDispatch>();
  
  const organization: Organization = useSelector(selectOrganization);
  const ingredients: StockIngredient[] = useSelector(selectIngredients);
  const categories: StockCategory[] = useSelector(selectCategories);
  const stockLoadings = useSelector(selectStockLoadings);
  useEffect(() => {
    if (organization) {
      if (ingredients.length == 0) {
        dispatch(getIngredients(organization.uuid));
      }
    }
  }, [organization]);

  useEffect(() => {
    if (!stockLoadings.post && showModal) {
      setShowModal(false);
      dispatch(getIngredients(organization.uuid));
    }
  }, [stockLoadings.post]);


  const [showModal, setShowModal] = useState(false);
  const [path, setPath] = useState('');
  const [categoryUuid, setCategoryUuid] = useState('none');
  const [item, setItem] = useState(new StockItem());
  const [option, setOption] = useState(new StockItem());
  const [preparation, setPreparation] = useState(new StockPreparation());
  const [ingredient, setIngredient] = useState(new StockIngredient());
  const [category, setCategory] = useState(new StockCategory());


  const handleCategoryChange = (event: SelectChangeEvent) => {
    if (event.target.value == 'new') {
      setCategory(new StockCategory());
      setPath('category');
      setShowModal(true);
    } else {
      setCategoryUuid(event.target.value);
    }
  }

  const displayItems = (ingredient: StockIngredient, ingIndex: number) => (
    <TableRow
      sx={{display: 'flex'}}
      key={ingredient.uuid}
    >
      <TableCell sx={{
        p: 0,
        width: '100%',
      }}>
        <Draggable
          handle=".handle"
          axis="y"
          defaultPosition={{x: 0, y: 0}}
          position={{x:0, y: 0}}
          bounds={{top: -ingIndex*30, bottom: (ingredients.length-ingIndex-1)*30}}
          scale={1}
          onDrag={(e, position) => {
            const {x, y} = position;
            const newIndex = Math.round(y / 30);
            if (dragIndex != newIndex) {
              setDragIndex(newIndex);
            }
          }}
          onStop={() => {
            if (dragIndex != 0) {
              const newRow = ingredient.row + dragIndex;
              var param = [];
              var items = [];
              for (var ing of ingredients) {
                if (ing.row == ingredient.row) {
                  items.push({...ing, row: newRow});
                  param.push({type: 'ingredient', uuid: ing.uuid!, row: newRow});
                } else if (ing.row > ingredient.row && ing.row <= newRow) {
                  items.push({...ing, row: ing.row - 1});
                  param.push({type: 'ingredient', uuid: ing.uuid!, row:ing.row - 1});
                } else if (ing.row < ingredient.row && ing.row >= newRow) {
                  items.push({...ing, row: ing.row + 1});
                  param.push({type: 'ingredient', uuid: ing.uuid!, row:ing.row + 1});
                } else {
                  items.push(ing);
                }
              }
              items.sort((i, j) => i.row - j.row);
              dispatch(setNewObject({ingredients:items}));
              dispatch(postStockRow(param));
            }
          }}
        >
          <List component="div" disablePadding>
            <Grid container>
              <Grid item className="handle" xs='auto' sx={{height: '30px', width:'50px !important', textAlign: 'center'}}>
                <DragHandleOutlinedIcon sx={{height: '30px'}} />
              </Grid>
              <Grid item xs>
                <ListItemButton sx={{ p:0, pr:'20px', height: '30px' }} onClick={() => {
                  setPath('ingredient');
                  setIngredient(ingredient);
                  setShowModal(true);
                }}>
                  <Grid item xs={10} sx={{height: '30px'}}>
                    <ListItemText sx={{height: '30px'}} primary={ingredient.name} />
                  </Grid>
                  <Grid item xs={2} sx={{height: '30px', textAlign: 'right'}}>
                    <ListItemText primary={'¥ ' + ingredient.price.toLocaleString()} />
                  </Grid>
                </ListItemButton>
              </Grid>
            </Grid>
          </List>
        </Draggable>
      </TableCell>
    </TableRow>
  )

  const Ref = forwardRef<HTMLDivElement, Props>(
    ({ path, item, option, brandUuid, preparation, ingredient, category }, ref) => {
      return <StockView
        children={{
          path: path,
          item: item,
          option: option,
          brandUuid: brandUuid,
          preparation: preparation,
          ingredient: ingredient,
          category: category,
        }}
        forwardRef={ref}
      />;
    }
  );

  return (
    <>
      {stockLoadings.ingredients ?
        <div style={{textAlign:'center'}}><CircularProgress /></div> :
        <>
          <Grid item xs={12} sx={{height: '60px'}}>
            <Select
              sx={{width: '100%', maxWidth: '300px'}}
              labelId="category-select-label"
              id="category-select"
              value={categoryUuid}
              defaultValue={'none'}
              label="Brand"
              onChange={handleCategoryChange}
            >
              {categories
                .map((c: StockCategory) => (
                  <MenuItem value={c.uuid} key={c.uuid}>{c.name}</MenuItem>
              ))}
                  <MenuItem value={'none'} key={'none'}>カテゴリーなし</MenuItem>
                  <MenuItem value={'new'} key={'new'}>カテゴリーを追加</MenuItem>
            </Select>
          </Grid>
          <TableContainer component={Paper} sx={{
            bgcolor: 'transparent',
            width: '100%',
            maxWidth: '1000px',
          }}>
            <Table aria-label='simple table'>
              <TableBody>
                {ingredients
                  .filter((ing: StockIngredient) => categoryUuid != 'none' ? ing.category_uuid == categoryUuid : !ing.category_uuid)
                  .map((ingredient: StockIngredient, ingIndex: number) => (
                  displayItems(ingredient, ingIndex)
                ))}
                <TableRow
                  sx={{m: '10px auto', display: 'flex'}}
                  key='new'
                >
                  <TableCell sx={{
                    p:'0',
                    width: '100%',
                    background: '#E84566',
                    borderRadius: '4px',
                    overflow: 'hidden',
                  }}>
                    <ListItemButton sx={{
                      background: '#F29C97',
                    }} onClick={() => {
                      setPath('ingredient');
                      setIngredient(new StockIngredient({category_uuid: categoryUuid}));
                      setShowModal(true);
                    }}>
                      <AddOutlined sx={{ m: 'auto' }} />
                    </ListItemButton>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Modal
            open={showModal}
            onClose={() => setShowModal(false)}
            sx={{
              justifyContent: 'center',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Ref 
              path={path}
              item={item}
              option={option}
              brandUuid={''}
              preparation={preparation}
              ingredient={ingredient}
              category={category}
            />
          </Modal>
        </>
      }
    </>
  )
};

export default StockIngredients;