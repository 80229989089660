import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';

import { CircularProgress } from '@mui/material';

const Top = () => {
	const navigate = useNavigate();
	useEffect(() => {
		onAuthStateChanged(auth, (user) => {
		let token = localStorage.getItem('token');
		if (user && token) {
				navigate('/home');
			} else {
				navigate('/signin');
			}
		});
	}, []); 
  
  return (
    <>
      <div className="signinView">
        <h1 className='FontL' style={{color: 'white', fontWeight: 'bold'}}>Unifire</h1>
        <CircularProgress />
      </div>
    </>
  );
};

export default Top;